import { Divider, Modal, Space, Typography } from 'antd'
import { formatCurrency } from '../../utils/string-utils'
import { renderStatus } from '../quanlytopup'
const { Text } = Typography

type FieldModalView = {
    modalView: { data: any | null, isOpen: boolean }
    setModalView: Function
}

export default function ModalView({ modalView, setModalView }: FieldModalView) {
    return (
        <Modal
            title='Chi tiết giao dịch'
            open={modalView.isOpen}
            footer={[]}
            onCancel={() => setModalView({ data: null, isOpen: false })}>
            <Divider />
            <div style={{ marginBottom: 10 }}>
                <Space>
                    <Text strong>Mã đơn hàng:</Text>
                    {modalView.data?.orderId}
                </Space>
            </div>
            <div>
                <Space>
                    <Text strong>Mã giao dịch:</Text>
                    {modalView.data?.tranId}
                </Space>
            </div>
            <Divider />
            <div style={{ marginBottom: 10 }}>
                <Space>
                    <Text strong>Số điện thoại:</Text>
                    {modalView.data?.customer}
                </Space>
            </div>
            <Text strong>Thông tin gói cước:</Text>
            <p>Tên gói: {modalView.data?.packageName}</p>
            <p>Mã gói: {modalView.data?.dataPack}</p>
            <p>Giá: {formatCurrency(Number(modalView.data?.price))}</p>
            <p>Loại: {modalView.data?.dataType || 'ADDON'}</p>
            <Divider />
            <Space>
                <Text strong>Trạng thái:</Text>
                {renderStatus(modalView.data?.return)}
            </Space>
            <Divider />
            <Space>
                <Text strong>Mô tả:</Text>
                {modalView.data?.description}
            </Space>
        </Modal>
    )
}
