import { useCallback, useEffect, useState } from 'react'
import { Button, DatePicker, Divider, Form, Input, Modal, Select, Space, TableProps } from 'antd'
import { SearchOutlined, UndoOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams, { FORMAT_DATE, TRANSACTION_CONNECTION_TYPE_CONNECTION, TRANSACTION_CONNECTION_TYPE_EXTENSION } from '../../assets/constants/default_params'
import { useLoading } from '../../context/LoadingContext'
import { apiGetListCompanies, apiGetListPackagesConnection, apiGetListPhoneAgencies, apiGetListTransactionConnection, apiGetListUsers, apiUpdateDataPackTransactionConnection, apiUpdateListSimTransactionConnection, apiUpdatePhoneNumberTransactionConnection } from '../../api'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { mbhStatusOptions, transactionConnectionTypeOptions } from '../../assets/constants/default_options'
import { ICompany } from '../../models/company.modal'
import { renderDateByUnix, renderStatus, renderType } from './method'
import { IUser } from '../../models/user.model'
import { formatCurrency } from '../../utils/string-utils'
import { toastError, toastSuccess } from '../../utils/toast'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'
import { IPartnerPackage } from '../../models/package.modal'
import dayjs from 'dayjs'

export default function KitSimOrder() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [userOptions, setUserOptions] = useState<{ label: string, value: Number }[]>([])
    const [transactionConnection, setTransactionConnection] = useState<any>({})
    const [agencies, setAgencies] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState<any>({})
    const [modalUpdateListSim, setModalUpdateListSim] = useState<any>({ isOpen: false, data: null })
    const [updateListSimValue, setUpdateListSimValue] = useState<any>({ phoneSim: '', serialSim: '' })
    const [modalUpdatePhoneNumber, setModalUpdatePhoneNumber] = useState<any>({ isOpen: false, data: null })
    const [updatePhoneNumberValue, setUpdatePhoneNumberValue] = useState<any>({ phoneAgency: '', phoneCustomer: '' })
    const [modalUpdateDataPack, setModalUpdateDataPack] = useState<any>({ isOpen: false, data: null })
    const [updateDataPackValue, setUpdateDataPackValue] = useState<any>({ cpCode: '', dataPack: '', startDate: null })
    const [cpCode, setCpCode] = useState<string>('')
    const [partnerPackageOptions, setPartnerPackageOptions] = useState<{ label: string, value: Number }[]>([])
    const { setIsLoading } = useLoading()
    const [formSearch] = Form.useForm()
    const { account } = useSelector(authSelector)

    const transactionConnectionColumns: TableProps<any>['columns'] = [
        {
            key: 'cpCode',
            title: 'Mã công ty',
            dataIndex: 'cpCode',
        },
        {
            key: 'ownerBy',
            title: 'Nhân sự đấu nối',
            dataIndex: 'ownerBy',
        },
        {
            key: 'phoneAgency',
            title: 'SĐT báo KH',
            dataIndex: 'phoneAgency',
            render: phoneAgency => phoneAgency ? agencies.find((agen: any) => agen.id === phoneAgency)?.phoneAgency : '',
        },
        {
            key: 'phoneSim',
            title: 'Msisdn',
            dataIndex: 'phoneSim',
        },
        {
            key: 'serialSim',
            title: 'Serial Sim',
            dataIndex: 'serialSim',
        },
        {
            key: 'phoneCustomer',
            title: 'SĐT Khách hàng',
            dataIndex: 'phoneCustomer',
        },
        {
            key: 'dataPack',
            title: 'Gói cước',
            dataIndex: 'dataPack',
        },
        {
            key: 'price',
            title: 'Giá',
            dataIndex: 'price',
            render: price => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'startDate',
            title: 'Ngày đấu nối',
            dataIndex: 'startDate',
            render: startDate => renderDateByUnix(startDate),
        },
        {
            key: 'endDate',
            title: 'Ngày hết hạn',
            dataIndex: 'endDate',
            render: endDate => renderDateByUnix(endDate),
        },
        {
            key: 'type',
            title: 'Loại gói cước',
            dataIndex: 'type',
            render: type => renderType(type),
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
            render: status => renderStatus(status),
        },
        (
            account?.canUpdateListSimTransactionConnection
            || account?.canUpdatePhoneNumberTransactionConnection
            || account?.canUpdateDataPackTransactionConnection
        ) ? {
            key: 'update',
            title: 'Cập nhật',
            render: data => (
                <Space>
                    {account?.canUpdateListSimTransactionConnection && (
                        <Button onClick={() => {
                            setUpdateListSimValue({ phoneSim: data.phoneSim, serialSim: data.serialSim })
                            setModalUpdateListSim({ isOpen: true, data })
                        }}>
                            Sim
                        </Button>
                    )}
                    {account?.canUpdatePhoneNumberTransactionConnection && (
                        <Button onClick={() => {
                            setUpdatePhoneNumberValue({
                                phoneAgency: data.phoneAgency ? agencies.find((agen: any) => agen.id === data.phoneAgency)?.phoneAgency : '',
                                phoneCustomer: data.phoneCustomer,
                            })
                            setModalUpdatePhoneNumber({ isOpen: true, data })
                        }}>
                            SĐT
                        </Button>
                    )}
                    {account?.canUpdateDataPackTransactionConnection && (
                        <Button onClick={() => {
                            setCpCode(data.cpCode)
                            setUpdateDataPackValue({
                                cpCode: data.cpCode,
                                dataPack: `${data.dataPack}::${data.type}::${data.price}`,
                                startDate: dayjs(renderDateByUnix(data.startDate)),
                            })
                            setModalUpdateDataPack({ isOpen: true, data })
                        }}>
                            Gói cước
                        </Button>
                    )}
                </Space>
            ),
        } : {},
    ]

    const fetchPhoneAgencies = useCallback(async (trans: any) => {
        const idAgencies = [] as string[]
        trans.map((tran: any) => {
            if (tran.phoneAgency && !idAgencies.includes(tran.phoneAgency)) {
                idAgencies.push(`'${tran.phoneAgency}'`)
            }
            return tran
        })
        if (idAgencies.length > 0) {
            const respAgency = await apiGetListPhoneAgencies({
                page: 1,
                size: idAgencies.length,
                condition: `"cpCode" = '${search.cpCode}' AND  id IN (${idAgencies.toString()})`
            })
            setAgencies(respAgency.data.phoneAgencies)
        }
    }, [search.cpCode])

    const fetchTransactionConnection = useCallback(async () => {
        if (!search.cpCode && !search.ownerBy && !search.type && !search.status && !search.phoneSim && !search.serialSim && !search.phoneCustomer) {
            setTransactionConnection({})
            return
        }
        try {
            setIsLoading(true)
            const resp = await apiGetListTransactionConnection({
                page: currentPage,
                size: pageSize,
                ...search,
            })
            if (resp.status === 200) {
                fetchPhoneAgencies(resp.data.transactions)
                setTransactionConnection(resp.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, fetchPhoneAgencies, currentPage, pageSize, search])

    const fetchCompanies = useCallback(async () => {
        try {
            const resp = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resp.data != null) {
                const options = resp.data.companies.map((item: ICompany) => ({
                    label: item.name,
                    value: item.code,
                }))
                setCompanyOptions(options)
            } else {
                setCompanyOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchPartnerPackages = useCallback(async () => {
        if (!cpCode) return
        try {
            const resp = await apiGetListPackagesConnection(cpCode)
            if (resp.data?.partner_packages === null) {
                return
            }
            const options = resp.data.partner_packages.map((item: IPartnerPackage) => {
                let label = item.name
                let value = `${item.code}::${item.type}::${item.price}`
                if (item.type === TRANSACTION_CONNECTION_TYPE_CONNECTION) {
                    label += '_Đấu mới'
                }
                if (item.type === TRANSACTION_CONNECTION_TYPE_EXTENSION) {
                    label += '_Gia hạn'
                }
                return { label, value }
            })
            setPartnerPackageOptions(options)
        } catch (error) {
            console.log(error)
        }
    }, [cpCode])

    const fetchUsers = useCallback(async () => {
        try {
            const resp = await apiGetListUsers({ limit: defaultParams.MAX_LIMIT })
            if (resp.data != null) {
                const options = resp.data.users.map((item: IUser) => ({
                    label: item.username,
                    value: item.username,
                }))
                setUserOptions(options)
            } else {
                setUserOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        fetchTransactionConnection()
        fetchCompanies()
        fetchPartnerPackages()
        fetchUsers()
    }, [fetchTransactionConnection, fetchCompanies, fetchPartnerPackages, fetchUsers])

    const handleSearch = ({ cpCode, ownerBy, type, status, phoneSim, serialSim, phoneCustomer, date }: any) => {
        if (!cpCode && !ownerBy && !type && !status && !phoneSim && !serialSim && !phoneCustomer) return
        let startDate, endDate
        if (date) {
            startDate = date[0] ? date[0]?.format('YYYY-MM-DD') : null
            endDate = date[1] ? date[1]?.format('YYYY-MM-DD') : null
        }
        if (cpCode) setCpCode(cpCode)
        setSearch({ cpCode, ownerBy, type, status, phoneSim, serialSim, phoneCustomer, startDate, endDate })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({})
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleUpdateListSim = async () => {
        try {
            if (!updateListSimValue.phoneSim || !updateListSimValue.serialSim) {
                toastError('Vui lòng điền đầy đủ thông tin!')
                return
            }
            setIsLoading(true)
            const { data } = await apiUpdateListSimTransactionConnection({
                id: modalUpdateListSim.data.id,
                phoneSim: updateListSimValue.phoneSim,
                serialSim: updateListSimValue.serialSim,
            })
            if (data.status !== '200') {
                toastError(data.message)
                return
            }
            fetchTransactionConnection()
            setModalUpdateListSim({ isOpen: false, data: null })
            toastSuccess('Cập nhật thông tin thành công!')
        } catch (error) {
            toastError('Có lỗi xảy ra!')
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpdatePhoneNumber = async () => {
        try {
            if (!updatePhoneNumberValue.phoneAgency && !updatePhoneNumberValue.phoneCustomer) {
                toastError('Vui lòng điền đầy đủ thông tin!')
                return
            }
            setIsLoading(true)
            const { data } = await apiUpdatePhoneNumberTransactionConnection({
                id: modalUpdatePhoneNumber.data.id,
                phoneAgency: updatePhoneNumberValue.phoneAgency,
                phoneCustomer: updatePhoneNumberValue.phoneCustomer,
            })
            if (data.status !== '200') {
                toastError(data.message)
                return
            }
            fetchTransactionConnection()
            setModalUpdatePhoneNumber({ isOpen: false, data: null })
            toastSuccess('Cập nhật thông tin thành công!')
        } catch (error) {
            toastError('Có lỗi xảy ra!')
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpdateDataPack = async () => {
        try {
            if (!updateDataPackValue.cpCode || !updateDataPackValue.dataPack || !updateDataPackValue.startDate) {
                toastError('Vui lòng điền đầy đủ thông tin!')
                return
            }
            setIsLoading(true)
            const dataPack = updateDataPackValue.dataPack.split('::')
            const startDate = dayjs(updateDataPackValue.startDate).format(FORMAT_DATE)
            const { data } = await apiUpdateDataPackTransactionConnection({
                id: modalUpdateDataPack.data.id,
                cpCode: updateDataPackValue.cpCode,
                dataPack: dataPack[0],
                packType: dataPack[1],
                startDate: startDate,
            })
            if (data.status !== '200') {
                toastError(data.message)
                return
            }
            fetchTransactionConnection()
            setModalUpdateDataPack({ isOpen: false, data: null })
            toastSuccess('Cập nhật thông tin thành công!')
        } catch (error) {
            toastError('Có lỗi xảy ra!')
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <Form form={formSearch} onFinish={handleSearch}>
                <div style={{ display: 'flex', alignItems: 'start', flexWrap: 'wrap', gap: 20 }}>
                    <Form.Item name='cpCode' label='Công ty'
                        rules={[{ required: true, message: 'Vui lòng chọn công ty' }]}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn công ty"
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='ownerBy' label='Nhân sự đấu nối'>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn nhân sự"
                            options={userOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='type' label='Loại giao dịch'>
                        <Select
                            style={{ width: 170 }}
                            showSearch
                            placeholder="Chọn loại giao dịch"
                            options={transactionConnectionTypeOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='status' label='Trạng thái'>
                        <Select
                            style={{ width: 170 }}
                            showSearch
                            placeholder="Chọn trạng thái"
                            options={mbhStatusOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='phoneCustomer' label='SĐT Khách hàng'>
                        <Input autoComplete='off' />
                    </Form.Item>
                    <Form.Item name='phoneSim' label='Msisdn'>
                        <Input autoComplete='off' />
                    </Form.Item>
                    <Form.Item name='serialSim' label='SerialSim'>
                        <Input autoComplete='off' />
                    </Form.Item>
                    <Form.Item name="date" label="Ngày đấu nối">
                        <DatePicker.RangePicker
                            format="YYYY-MM-DD"
                            picker="date" />
                    </Form.Item>
                    <Space style={{ marginBottom: 24 }}>
                        <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </div>
            </Form>
            <CustomTable
                namePage='giao dịch'
                columns={transactionConnectionColumns}
                dataSource={transactionConnection.transactions}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={transactionConnection.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal
                title='Cập nhật thông tin sim'
                open={modalUpdateListSim.isOpen}
                footer={null}
                onCancel={() => setModalUpdateListSim({ isOpen: false, data: null })}>
                <Divider />
                <div style={{ marginBottom: 20 }}>
                    <div style={{ marginBottom: 10 }}>Msisdn</div>
                    <Input
                        value={updateListSimValue.phoneSim}
                        onChange={e => setUpdateListSimValue({ ...updateListSimValue, phoneSim: e.target.value })}
                    />
                </div>
                <div style={{ marginBottom: 20 }}>
                    <div style={{ marginBottom: 10 }}>Serial Sim</div>
                    <Input
                        value={updateListSimValue.serialSim}
                        onChange={e => setUpdateListSimValue({ ...updateListSimValue, serialSim: e.target.value })}
                    />
                </div>
                <div style={{ textAlign: 'right' }}>
                    <Button onClick={handleUpdateListSim} type='primary' htmlType='submit'>Cập nhật</Button>
                </div>
            </Modal>
            <Modal
                title='Cập nhật thông tin số điện thoại'
                open={modalUpdatePhoneNumber.isOpen}
                footer={null}
                onCancel={() => setModalUpdatePhoneNumber({ isOpen: false, data: null })}>
                <Divider />
                <div style={{ marginBottom: 20 }}>
                    <div style={{ marginBottom: 10 }}>SĐT Đại lý</div>
                    <Input
                        value={updatePhoneNumberValue.phoneAgency}
                        onChange={e => setUpdatePhoneNumberValue({ ...updatePhoneNumberValue, phoneAgency: e.target.value })}
                    />
                </div>
                <div style={{ marginBottom: 20 }}>
                    <div style={{ marginBottom: 10 }}>SĐT Khách hàng</div>
                    <Input
                        value={updatePhoneNumberValue.phoneCustomer}
                        onChange={e => setUpdatePhoneNumberValue({ ...updatePhoneNumberValue, phoneCustomer: e.target.value })}
                    />
                </div>
                <div style={{ textAlign: 'right' }}>
                    <Button onClick={handleUpdatePhoneNumber} type='primary' htmlType='submit'>Cập nhật</Button>
                </div>
            </Modal>
            <Modal
                title='Cập nhật thông tin gói cước'
                open={modalUpdateDataPack.isOpen}
                footer={null}
                onCancel={() => setModalUpdateDataPack({ isOpen: false, data: null })}>
                <Divider />
                <div style={{ marginBottom: 20 }}>
                    <div style={{ marginBottom: 10 }}>Công ty</div>
                    <Select
                        value={updateDataPackValue.cpCode}
                        onChange={value => {
                            setCpCode(value)
                            setUpdateDataPackValue({ ...updateDataPackValue, cpCode: value })
                        }}
                        style={{ width: '100%' }}
                        showSearch
                        options={companyOptions}
                        filterOption={filterOptionByLabel}
                    />
                </div>
                <div style={{ marginBottom: 20 }}>
                    <div style={{ marginBottom: 10 }}>Gói cước</div>
                    <Select
                        value={updateDataPackValue.dataPack}
                        onChange={value => setUpdateDataPackValue({ ...updateDataPackValue, dataPack: value })}
                        style={{ width: '100%' }}
                        showSearch
                        options={partnerPackageOptions}
                        filterOption={filterOptionByLabel}
                    />
                </div>
                <div style={{ marginBottom: 20 }}>
                    <div style={{ marginBottom: 10 }}>Ngày đấu nối</div>
                    <DatePicker
                        style={{ width: '100%' }}
                        value={updateDataPackValue.startDate}
                        onChange={value => setUpdateDataPackValue({ ...updateDataPackValue, startDate: value })}
                        format={FORMAT_DATE}
                        placeholder='Ngày bắt đầu'
                        picker='date'
                    />
                </div>
                <div style={{ textAlign: 'right' }}>
                    <Button onClick={handleUpdateDataPack} type='primary' htmlType='submit'>Cập nhật</Button>
                </div>
            </Modal>
        </div>
    )
}
