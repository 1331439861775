import { Breadcrumb } from 'antd'
import { useLocation } from 'react-router-dom'

const routes: Record<string, string> = {
  '/profile': 'Trang cá nhân',
  '/dashboard': 'Dashboard',
  '/dashboard/dau-noi': 'Đấu nối',
  '/dashboard/gia-han': 'Gia hạn',
  '/dau-noi': 'Đấu nối',
  '/top-up': 'Quản lý nạp tiền',
  '/top-up/overview': 'Tổng quan',
  '/top-up/order': 'Đơn hàng',
  '/top-up/history': 'Lịch sử giao dịch',
  '/top-up/detail-transaction': 'Chi tiết giao dịch',
  '/root-account': 'Quản lý tài khoản gốc',
  '/root-account/overview': 'Tổng quan',
  '/root-account/order': 'Đơn hàng',
  '/root-account/history': 'Lịch sử giao dịch',
  '/root-account/detail-transaction': 'Chi tiết giao dịch',
  '/root-account/detail-package': 'Danh sách gói cước',
  '/register-addon': 'Quản lý data sponsor',
  '/register-addon/overview': 'Tổng quan',
  '/register-addon/order': 'Đơn hàng',
  '/register-addon/history': 'Lịch sử giao dịch',
  '/register-addon/detail-transaction': 'Chi tiết giao dịch',
  '/register-addon/detail-package': 'Danh sách gói cước',
  '/kit-sim': 'Quản lý đấu nối',
  '/kit-sim/connect': 'Đấu nối',
  '/kit-sim/order': 'Đơn hàng',
  '/kit-sim/history': 'Lịch sử',
  '/kit-sim/detail-transaction': 'Chi tiết',
  '/card-sim': 'Quản lý card sim',
  '/card-sim/order': 'Đơn hàng card sim',
  '/card-sim/history': 'Lịch sử card sim',
  '/card-sim/detail-transaction': 'Chi tiết card sim',
  '/customer': 'Khách hàng',
  '/customer/detail': 'Thông tin khách hàng',
  '/agency': 'Đại lý',
  '/agency/detail': 'Thông tin đại lý',
  '/telesale': 'Gia hạn',
  '/overview': 'Tổng quan',
  '/sim-passport': 'Sim Passport',
  '/company': 'Công ty',
  '/company/detail': 'Chi tiết công ty',
  '/company/create': 'Thêm mới',
  '/company/update': 'Cập nhật',
  '/package': 'Quản lý gói cước',
  '/package/overview': 'Tổng quan',
  '/package/overview/create': 'Thêm mới',
  '/package/overview/update': 'Cập nhật',
  '/package/detail': 'Đối tác',
  '/package/detail/create': 'Thêm mới',
  '/package/detail/update': 'Cập nhật',
  '/telegram': 'Telegram',
  '/telegram/create': 'Thêm mới',
  '/telegram/update': 'Cập nhật',
  '/transaction': 'Giao dịch',
  '/transaction/create': 'Thêm mới',
  '/user': 'Quản trị viên',
  '/user/create': 'Thêm mới',
  '/user/update': 'Cập nhật',
  '/role': 'Phân quyền',
  '/role/create': 'Thêm mới',
  '/role/update': 'Cập nhật',
}

const BreadcrumbItem = () => {
  const location = useLocation()

  const pathSnippets = location.pathname.split('/').filter((i) => i)
  const extraBreadcrumbItems = pathSnippets.map((str, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`

    let breadcrumbName = ''
    Object.keys(routes).forEach((pattern) => {
      const regex = new RegExp(pattern)

      if (regex.test(url)) {
        breadcrumbName = routes[pattern]
      }
    })

    return {
      key: url,
      title: breadcrumbName,
    }
  })

  return <Breadcrumb items={extraBreadcrumbItems} />
}
export default BreadcrumbItem
