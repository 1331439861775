import { permissionOptions } from "../assets/constants/default_options";
import { ADMIN_USERNAME } from "../config/constants";
import { IBasePermission, IPermission } from "../models/role.model";
import { IUser } from "../models/user.model";
import _ from 'lodash';

export const convertListStringToPermission = (perms: string[]) => {
    return perms?.length > 0 ? perms.map((item: | string) => {
        const [action, resource] = item?.split('::')
        return { action, resource }
    }) : []
}

export const convertListPermissionToString = (perms: IPermission[] | undefined) => {
    return perms?.map((item: IPermission) => {
        return `${item.action}::${item.resource}`
    })
}

export const convertListGroupPermissionToString = (perms: IPermission[] | undefined, group: string) => {
    return perms?.map((perm: IPermission) => permissionOptions
        .map(item => item.group === group && item.value === `${perm.action}::${perm.resource}` && item.value)
        .filter(item => item)[0],
    ).filter(item => item)
}

export const convertListBasePermissionToOption = (perms: IBasePermission[]) => {
    return perms.map((item: IBasePermission) => ({
        label: item.description,
        value: `${item.action}::${item.resource}`,
        group: item.group,
        name: item.group_name,
    }))
}

const isAdmin = ADMIN_USERNAME

// Companies
const canGetListCompanies = 'GET::/api/otek-dashboard/companies'
const canAddCompany = 'POST::/api/otek-dashboard/companies'
const canUpdateCompany = 'PATCH::/api/otek-dashboard/companies'
const canDeleteCompany = 'DELETE::/api/otek-dashboard/companies'

// Packages
const canGetListPackages = 'GET::/api/otek-dashboard/packages'
const canAddPackage = 'POST::/api/otek-dashboard/packages'
const canAddListPackages = 'POST::/api/otek-dashboard/packages/add-list-packages'
const canUpdatePackage = 'PATCH::/api/otek-dashboard/packages'
const canDeletePackage = 'DELETE::/api/otek-dashboard/packages'

// Partner Packages
const canGetListPartnerPackages = 'GET::/api/otek-dashboard/partner-packages'
const canAddPartnerPackage = 'POST::/api/otek-dashboard/partner-packages'
const canAddListPartnerPackages = 'POST::/api/otek-dashboard/partner-packages/add-list-partner-packages'
const canDeletePartnerPackage = 'DELETE::/api/otek-dashboard/partner-packages'
const canGetListPackagesTkgDashboard = 'GET::/api/otek-dashboard/partner-packages/get-list-packages-tkg-dashboard'
const canGetListPackagesSponsor = 'GET::/api/otek-dashboard/partner-packages/get-list-packages-sponsor'
const canGetListPackagesConnection = 'GET::/api/otek-dashboard/partner-packages/get-list-packages-connection'

// Telegrams
const canGetListTelegrams = 'GET::/api/otek-dashboard/telegrams'
const canAddTelegram = 'POST::/api/otek-dashboard/telegrams'
const canUpdateTelegram = 'PATCH::/api/otek-dashboard/telegrams'
const canDeleteTelegram = 'DELETE::/api/otek-dashboard/telegrams'

// Transactions
const canGetListTransactions = 'GET::/api/otek-dashboard/transactions'
const canRenewTransaction = 'PATCH::/api/otek-dashboard/transactions'
const canAddTransaction = 'POST::/api/otek-dashboard/transactions'
const canConnectTransaction = 'POST::/api/otek-dashboard/transactions/connect'

// Users
const canGetListUsers = 'GET::/api/otek-dashboard/users'
const canAddUser = 'POST::/api/otek-dashboard/users'
const canUpdateUser = 'PATCH::/api/otek-dashboard/users'
const canDeleteUser = 'DELETE::/api/otek-dashboard/users'

// Roles
const canGetListBasePermissions = 'GET::/api/otek-dashboard/base-permissions'
const canGetListRoles = 'GET::/api/otek-dashboard/roles'
const canAddRole = 'POST::/api/otek-dashboard/roles'
const canUpdateRole = 'PATCH::/api/otek-dashboard/roles'
const canDeleteRole = 'DELETE::/api/otek-dashboard/roles'

// Sim Passports
const canGetListSimPassports = 'GET::/api/otek-dashboard/sim-passports'
const canUpdateSimPassport = 'PATCH::/api/otek-dashboard/sim-passports'

// Mbh Topups
const canCheckBalanceTopup = 'GET::/api/otek-dashboard/mbh-topups/check-balance-topup'
const canGetListOrderTopups = 'GET::/api/otek-dashboard/mbh-topups/get-list-order-topups'
const canGetListTransactionTopupsByOrderId = 'GET::/api/otek-dashboard/mbh-topups/get-list-transaction-topups-by-order-id'
const canCheckTransactionTopupOnResource = 'GET::/api/otek-dashboard/mbh-topups/check-transaction-topup-on-resource'
const canCheckTransactionTopupOnAdapter = 'GET::/api/otek-dashboard/mbh-topups/check-transaction-topup-on-adapter'
const canUploadOrderTopupByExcel = 'POST::/api/otek-dashboard/mbh-topups/upload-order-topup-by-excel'
const canTopupByPhone = 'POST::/api/otek-dashboard/mbh-topups/topup-by-phone'
const canTopupByOrderId = 'POST::/api/otek-dashboard/mbh-topups/topup-by-order-id'
const canUpdateStatusTransactionTopup = 'PATCH::/api/otek-dashboard/mbh-topups/update-status-transaction-topup'
const canUpdateLockOrderTopup = 'PATCH::/api/otek-dashboard/mbh-topups/update-lock-order-topup'

// Mbh Tkg Dashboard
const canGetDynamicPartnerOffer = 'GET::/api/otek-dashboard/mbh-tkg-dashboard/get-dynamic-partner-offer'
const canGetListOrderTkgDashboard = 'GET::/api/otek-dashboard/mbh-tkg-dashboard/get-list-order-tkg-dashboard'
const canGetListTransactionTkgDashboardByOrderId = 'GET::/api/otek-dashboard/mbh-tkg-dashboard/get-list-transaction-tkg-dashboard-by-order-id'
const canCheckTransactionTkgOnResource = 'GET::/api/otek-dashboard/mbh-tkg-dashboard/check-transaction-tkg-dashboard-on-resource'
const canCheckTransactionTkgOnAdapter = 'GET::/api/otek-dashboard/mbh-tkg-dashboard/check-transaction-tkg-dashboard-on-adapter'
const canUploadOrderTkgByExcel = 'POST::/api/otek-dashboard/mbh-tkg-dashboard/upload-order-tkg-dashboard-by-excel'
const canTkgByPhone = 'POST::/api/otek-dashboard/mbh-tkg-dashboard/tkg-dashboard-by-phone'
const canTkgByOrderId = 'POST::/api/otek-dashboard/mbh-tkg-dashboard/tkg-dashboard-by-order-id'
const canUpdateStatusTransactionTkgDashboard = 'PATCH::/api/otek-dashboard/mbh-tkg-dashboard/update-status-transaction-tkg-dashboard'
const canUpdateLockOrderTkgDashboard = 'PATCH::/api/otek-dashboard/mbh-tkg-dashboard/update-lock-order-tkg-dashboard'

// Data Sponsor
const canGetDynamicPartnerOfferDataSponsor = 'GET::/api/otek-dashboard/data-sponsor/get-dynamic-partner-offer-data-sponsor'
const canGetListOrderDataSponsor = 'GET::/api/otek-dashboard/data-sponsor/get-list-order-data-sponsor'
const canGetListTransactionDataSponsorByOrderId = 'GET::/api/otek-dashboard/data-sponsor/get-list-transaction-data-sponsor-by-order-id'
const canCheckTransactionDataSponsorOnResource = 'GET::/api/otek-dashboard/data-sponsor/check-transaction-data-sponsor-on-resource'
const canCheckTransactionDataSponsorOnAdapter = 'GET::/api/otek-dashboard/data-sponsor/check-transaction-data-sponsor-on-adapter'
const canUploadOrderDataSponsorByExcel = 'POST::/api/otek-dashboard/data-sponsor/upload-order-data-sponsor-by-excel'
const canDataSponsorByPhone = 'POST::/api/otek-dashboard/data-sponsor/data-sponsor-by-phone'
const canDataSponsorByOrderId = 'POST::/api/otek-dashboard/data-sponsor/data-sponsor-by-order-id'
const canCheckWalletBalancePostpaid = 'GET::/api/otek-dashboard/data-sponsor/check-wallet-balance-postpaid'
const canUpdateStatusTransactionDataSponsor = 'PATCH::/api/otek-dashboard/data-sponsor/update-status-transaction-data-sponsor'
const canUpdateLockOrderDataSponsor = 'PATCH::/api/otek-dashboard/data-sponsor/update-lock-order-data-sponsor'

// User Partner
const canGetListUserPartners = 'GET::/api/otek-dashboard/user-partners/get-list-user-partners'
const canAddUserPartner = 'POST::/api/otek-dashboard/user-partners/add-user-partner'
const canUpdateUserPartner = 'PATCH::/api/otek-dashboard/user-partners/update-user-partner'
const canDeleteUserPartner = 'DELETE::/api/otek-dashboard/user-partners/delete-user-partner'

// Transaction Connection
const canGetListTransactionConnection = 'GET::/api/otek-dashboard/transaction-connection/get-list-transaction-connection'
const canAddListTransactionConnection = 'POST::/api/otek-dashboard/transaction-connection/add-list-transaction-connection'
const canUpdateListTransactionConnection = 'PUT::/api/otek-dashboard/transaction-connection/update-list-transaction-connection'
const canAddTransactionConnectionBySyntax = 'POST::/api/otek-dashboard/transaction-connection/add-transaction-connection'
const canGetListTransactionConnectionExpire = 'GET::/api/otek-dashboard/transaction-connection/get-list-transaction-connection-expire'
const canGetListTransactionConnectionView = 'GET::/api/otek-dashboard/transaction-connection/get-list-transaction-connection-view'
const canUpdateListSimTransactionConnection = 'PUT::/api/otek-dashboard/transaction-connection/update-list-sim-transaction-connection'
const canUpdatePhoneNumberTransactionConnection = 'PUT::/api/otek-dashboard/transaction-connection/update-phone-number-transaction-connection'
const canUpdateDataPackTransactionConnection = 'PUT::/api/otek-dashboard/transaction-connection/update-data-pack-transaction-connection'

// Statistical Service
const canGetRevenueConnection = 'GET::/api/otek-dashboard/statistical-service/get-revenue-connection'
const canGetRevenueDateConnection = 'GET::/api/otek-dashboard/statistical-service/get-date-revenue-connection'
const canGetStatisticalTypeConnection = 'GET::/api/otek-dashboard/statistical-service/get-statistical-type-connection'
const canGetStatisticalDateConnection = 'GET::/api/otek-dashboard/statistical-service/get-statistical-date-connection'
const canGetStatisticalPackDurationConnection = 'GET::/api/otek-dashboard/statistical-service/get-statistical-pack-duration-connection'
const canGetStatisticalTypeExpiration = 'GET::/api/otek-dashboard/statistical-service/get-statistical-type-expiration'
const canGetStatisticalDateExpiration = 'GET::/api/otek-dashboard/statistical-service/get-statistical-date-expiration'
const canGetStatisticalDateExtension = 'GET::/api/otek-dashboard/statistical-service/get-statistical-date-extension'
const canRecalculateStatistics = 'PATCH::/api/otek-dashboard/statistical-service/recalculate-statistics'
const canGetStatisticalDateCalling = 'GET::/api/otek-dashboard/statistical-service/get-statistical-date-calling'
const canGetStatisticalTypeCalling = 'GET::/api/otek-dashboard/statistical-service/get-statistical-type-calling'

// Customer Resource
const canGetListCustomerResource = 'GET::/api/otek-dashboard/customer-resource/get-list-customer-resource'
const canAddListCustomerResource = 'POST::/api/otek-dashboard/customer-resource/add-list-customer-resource'
const canUpdateListCustomerResource = 'PUT::/api/otek-dashboard/customer-resource/update-list-customer-resource'

// Phone Agency
const canGetListPhoneAgencies = 'GET::/api/otek-dashboard/phone-agencies/get-list-phone-agencies'
const canAddListPhoneAgencies = 'POST::/api/otek-dashboard/phone-agencies/add-list-phone-agencies'
const canUpdateListPhoneAgencies = 'PUT::/api/otek-dashboard/phone-agencies/update-list-phone-agencies'

// Call Info
const canGetListCallInfo = 'GET::/api/otek-dashboard/call-info/get-list-call-info'
const canAddListCallTelesale = 'POST::/api/otek-dashboard/call-info/add-list-call-telesale'
const canAddLogFeedback = 'POST::/api/otek-dashboard/call-info/add-log-feedback'
const canUpdateListCallInfo = 'PUT::/api/otek-dashboard/call-info/update-list-call-info'

// Call Log
const canGetListCallLog = 'GET::/api/otek-dashboard/call-log/get-list-call-log'
const canAddListCallLog = 'POST::/api/otek-dashboard/call-log/add-list-call-log'
const canUpdateListCallLog = 'PUT::/api/otek-dashboard/call-log/update-list-call-log'

export const setUserPermissions = (user: IUser) => {
    const userPermission = user.listPermission

    user.isAdmin = user.username === ADMIN_USERNAME
    if (user.isAdmin) {
        user.listPermission?.push(ADMIN_USERNAME)
    }

    // Companies
    user.canGetListCompanies = userPermission?.includes(canGetListCompanies)
    user.canAddCompany = userPermission?.includes(canAddCompany)
    user.canAddListPackages = userPermission?.includes(canAddListPackages)
    user.canUpdateCompany = userPermission?.includes(canUpdateCompany)
    user.canDeleteCompany = userPermission?.includes(canDeleteCompany)
    user.canCompanyManagement = user.canGetListCompanies
        || user.canAddCompany
        || user.canUpdateCompany
        || user.canDeleteCompany

    // Packages
    user.canGetListPackages = userPermission?.includes(canGetListPackages)
    user.canAddPackage = userPermission?.includes(canAddPackage)
    user.canAddListPackages = userPermission?.includes(canAddListPackages)
    user.canUpdatePackage = userPermission?.includes(canUpdatePackage)
    user.canDeletePackage = userPermission?.includes(canDeletePackage)
    user.canPackageManagement = user.canGetListPackages
        || user.canAddPackage
        || user.canAddListPackages
        || user.canUpdatePackage
        || user.canDeletePackage

    // Partner Packages
    user.canGetListPartnerPackages = userPermission?.includes(canGetListPartnerPackages)
    user.canAddPartnerPackage = userPermission?.includes(canAddPartnerPackage)
    user.canAddListPartnerPackages = userPermission?.includes(canAddListPartnerPackages)
    user.canDeletePartnerPackage = userPermission?.includes(canDeletePartnerPackage)
    user.canGetListPackagesTkgDashboard = userPermission?.includes(canGetListPackagesTkgDashboard)
    user.canGetListPackagesSponsor = userPermission?.includes(canGetListPackagesSponsor)
    user.canGetListPackagesConnection = userPermission?.includes(canGetListPackagesConnection)
    user.canPartnerPackageManagement = user.canGetListPartnerPackages
        || user.canAddPartnerPackage
        || user.canAddListPartnerPackages
        || user.canDeletePartnerPackage
        || user.canGetListPackagesTkgDashboard
        || user.canGetListPackagesSponsor
        || user.canGetListPackagesConnection

    // Telegrams
    user.canGetListTelegrams = userPermission?.includes(canGetListTelegrams)
    user.canAddTelegram = userPermission?.includes(canAddTelegram)
    user.canUpdateTelegram = userPermission?.includes(canUpdateTelegram)
    user.canDeleteTelegram = userPermission?.includes(canDeleteTelegram)
    user.canTelegramManagement = user.canGetListTelegrams
        || user.canAddTelegram
        || user.canUpdateTelegram
        || user.canDeleteTelegram

    // Transactions
    user.canGetListTransactions = userPermission?.includes(canGetListTransactions)
    user.canRenewTransaction = userPermission?.includes(canRenewTransaction)
    user.canAddTransaction = userPermission?.includes(canAddTransaction)
    user.canConnectTransaction = userPermission?.includes(canConnectTransaction)
    user.canTransactionManagement = user.canGetListTransactions
        || user.canRenewTransaction
        || user.canAddTransaction
        || user.canConnectTransaction

    // Users
    user.canGetListUsers = userPermission?.includes(canGetListUsers)
    user.canAddUser = userPermission?.includes(canAddUser)
    user.canUpdateUser = userPermission?.includes(canUpdateUser)
    user.canDeleteUser = userPermission?.includes(canDeleteUser)
    user.canUserManagement = user.canGetListUsers
        || user.canAddUser
        || user.canUpdateUser
        || user.canDeleteUser

    // Roles
    user.canGetListBasePermissions = userPermission?.includes(canGetListBasePermissions)
    user.canGetListRoles = userPermission?.includes(canGetListRoles)
    user.canAddRole = userPermission?.includes(canAddRole)
    user.canUpdateRole = userPermission?.includes(canUpdateRole)
    user.canDeleteRole = userPermission?.includes(canDeleteRole)
    user.canRoleManagement = user.canGetListBasePermissions
        || user.canGetListRoles
        || user.canAddRole
        || user.canUpdateRole
        || user.canDeleteRole

    // Sim Passports
    user.canGetListSimPassports = userPermission?.includes(canGetListSimPassports)
    user.canUpdateSimPassport = userPermission?.includes(canUpdateSimPassport)
    user.canSimPassportManagement = user.canGetListSimPassports
        || user.canUpdateSimPassport

    //Topup
    user.canCheckBalanceTopup = userPermission?.includes(canCheckBalanceTopup)
    user.canGetListOrderTopups = userPermission?.includes(canGetListOrderTopups)
    user.canGetListTransactionTopupsByOrderId = userPermission?.includes(canGetListTransactionTopupsByOrderId)
    user.canCheckTransactionTopupOnResource = userPermission?.includes(canCheckTransactionTopupOnResource)
    user.canCheckTransactionTopupOnAdapter = userPermission?.includes(canCheckTransactionTopupOnAdapter)
    user.canUploadOrderTopupByExcel = userPermission?.includes(canUploadOrderTopupByExcel)
    user.canTopupByPhone = userPermission?.includes(canTopupByPhone)
    user.canTopupByOrderId = userPermission?.includes(canTopupByOrderId)
    user.canUpdateStatusTransactionTopup = userPermission?.includes(canUpdateStatusTransactionTopup)
    user.canUpdateLockOrderTopup = userPermission?.includes(canUpdateLockOrderTopup)
    user.canTopupManagement = user.canCheckBalanceTopup
        || user.canGetListOrderTopups
        || user.canGetListTransactionTopupsByOrderId
        || user.canCheckTransactionTopupOnResource
        || user.canCheckTransactionTopupOnAdapter
        || user.canUploadOrderTopupByExcel
        || user.canTopupByPhone
        || user.canTopupByOrderId
        || user.canUpdateStatusTransactionTopup
        || user.canUpdateLockOrderTopup

    //Tkg Dashboard
    user.canGetDynamicPartnerOffer = userPermission?.includes(canGetDynamicPartnerOffer)
    user.canGetListOrderTkgDashboard = userPermission?.includes(canGetListOrderTkgDashboard)
    user.canGetListTransactionTkgDashboardByOrderId = userPermission?.includes(canGetListTransactionTkgDashboardByOrderId)
    user.canCheckTransactionTkgOnResource = userPermission?.includes(canCheckTransactionTkgOnResource)
    user.canCheckTransactionTkgOnAdapter = userPermission?.includes(canCheckTransactionTkgOnAdapter)
    user.canUploadOrderTkgByExcel = userPermission?.includes(canUploadOrderTkgByExcel)
    user.canTkgByPhone = userPermission?.includes(canTkgByPhone)
    user.canTkgByOrderId = userPermission?.includes(canTkgByOrderId)
    user.canUpdateStatusTransactionTkgDashboard = userPermission?.includes(canUpdateStatusTransactionTkgDashboard)
    user.canUpdateLockOrderTkgDashboard = userPermission?.includes(canUpdateLockOrderTkgDashboard)
    user.canTkgDashboardManagement = user.canGetDynamicPartnerOffer
        || user.canGetListOrderTkgDashboard
        || user.canGetListTransactionTkgDashboardByOrderId
        || user.canCheckTransactionTkgOnResource
        || user.canCheckTransactionTkgOnAdapter
        || user.canUploadOrderTkgByExcel
        || user.canTkgByPhone
        || user.canTkgByOrderId
        || user.canUpdateLockOrderTkgDashboard
        || user.canUpdateStatusTransactionTkgDashboard

    //Data Sponsor
    user.canGetDynamicPartnerOfferDataSponsor = userPermission?.includes(canGetDynamicPartnerOfferDataSponsor)
    user.canGetListOrderDataSponsor = userPermission?.includes(canGetListOrderDataSponsor)
    user.canGetListTransactionDataSponsorByOrderId = userPermission?.includes(canGetListTransactionDataSponsorByOrderId)
    user.canCheckTransactionDataSponsorOnResource = userPermission?.includes(canCheckTransactionDataSponsorOnResource)
    user.canCheckTransactionDataSponsorOnAdapter = userPermission?.includes(canCheckTransactionDataSponsorOnAdapter)
    user.canUploadOrderDataSponsorByExcel = userPermission?.includes(canUploadOrderDataSponsorByExcel)
    user.canDataSponsorByPhone = userPermission?.includes(canDataSponsorByPhone)
    user.canDataSponsorByOrderId = userPermission?.includes(canDataSponsorByOrderId)
    user.canCheckWalletBalancePostpaid = userPermission?.includes(canCheckWalletBalancePostpaid)
    user.canUpdateStatusTransactionDataSponsor = userPermission?.includes(canUpdateStatusTransactionDataSponsor)
    user.canUpdateLockOrderDataSponsor = userPermission?.includes(canUpdateLockOrderDataSponsor)
    user.canDataSponsorManagement = user.canGetDynamicPartnerOffer
        || user.canGetListOrderDataSponsor
        || user.canGetListTransactionDataSponsorByOrderId
        || user.canCheckTransactionDataSponsorOnResource
        || user.canCheckTransactionDataSponsorOnAdapter
        || user.canUploadOrderDataSponsorByExcel
        || user.canDataSponsorByPhone
        || user.canDataSponsorByOrderId
        || user.canCheckWalletBalancePostpaid
        || user.canUpdateStatusTransactionDataSponsor
        || user.canUpdateLockOrderDataSponsor

    // User Partner
    user.canGetListUserPartners = userPermission?.includes(canGetListUserPartners)
    user.canAddUserPartner = userPermission?.includes(canAddUserPartner)
    user.canUpdateUserPartner = userPermission?.includes(canUpdateUserPartner)
    user.canDeleteUserPartner = userPermission?.includes(canDeleteUserPartner)
    user.canUserPartnerManagement = user.canGetListUserPartners
        || user.canAddUser
        || user.canUpdateUser
        || user.canDeleteUser

    // Transaction Connection
    user.canGetListTransactionConnection = userPermission?.includes(canGetListTransactionConnection)
    user.canAddListTransactionConnection = userPermission?.includes(canAddListTransactionConnection)
    user.canUpdateListTransactionConnection = userPermission?.includes(canUpdateListTransactionConnection)
    user.canAddTransactionConnectionBySyntax = userPermission?.includes(canAddTransactionConnectionBySyntax)
    user.canGetListTransactionConnectionExpire = userPermission?.includes(canGetListTransactionConnectionExpire)
    user.canGetListTransactionConnectionView = userPermission?.includes(canGetListTransactionConnectionView)
    user.canUpdateListSimTransactionConnection = userPermission?.includes(canUpdateListSimTransactionConnection)
    user.canUpdatePhoneNumberTransactionConnection = userPermission?.includes(canUpdatePhoneNumberTransactionConnection)
    user.canUpdateDataPackTransactionConnection = userPermission?.includes(canUpdateDataPackTransactionConnection)
    user.canTransactionConnectionManagement = user.canGetListTransactionConnection
        || user.canAddListTransactionConnection
        || user.canUpdateListTransactionConnection
        || user.canAddTransactionConnectionBySyntax
        || user.canGetListTransactionConnectionExpire
        || user.canGetListTransactionConnectionView

    // Statistical Service
    user.canGetRevenueConnection = userPermission?.includes(canGetRevenueConnection)
    user.canGetRevenueDateConnection = userPermission?.includes(canGetRevenueDateConnection)
    user.canGetStatisticalTypeConnection = userPermission?.includes(canGetStatisticalTypeConnection)
    user.canGetStatisticalDateConnection = userPermission?.includes(canGetStatisticalDateConnection)
    user.canGetStatisticalPackDurationConnection = userPermission?.includes(canGetStatisticalPackDurationConnection)
    user.canGetStatisticalTypeExpiration = userPermission?.includes(canGetStatisticalTypeExpiration)
    user.canGetStatisticalDateExpiration = userPermission?.includes(canGetStatisticalDateExpiration)
    user.canGetStatisticalDateExtension = userPermission?.includes(canGetStatisticalDateExtension)
    user.canRecalculateStatistics = userPermission?.includes(canRecalculateStatistics)
    user.canGetStatisticalDateCalling = userPermission?.includes(canGetStatisticalDateCalling)
    user.canGetStatisticalTypeCalling = userPermission?.includes(canGetStatisticalTypeCalling)
    user.canStatisticalServiceManagement = user.canGetRevenueConnection
        || user.canGetStatisticalTypeConnection
        || user.canGetStatisticalDateConnection
        || user.canGetStatisticalPackDurationConnection
        || user.canGetStatisticalTypeExpiration
        || user.canGetStatisticalDateExpiration
        || user.canGetStatisticalDateExtension
        || user.canRecalculateStatistics
        || user.canGetStatisticalDateCalling
        || user.canGetStatisticalTypeCalling

    // Customer Resource
    user.canGetListCustomerResource = userPermission?.includes(canGetListCustomerResource)
    user.canAddListCustomerResource = userPermission?.includes(canAddListCustomerResource)
    user.canUpdateListCustomerResource = userPermission?.includes(canUpdateListCustomerResource)
    user.canCustomerResourceManagement = user.canGetListCustomerResource
        || user.canAddListCustomerResource
        || user.canUpdateListCustomerResource

    // Phone Agency
    user.canGetListPhoneAgencies = userPermission?.includes(canGetListPhoneAgencies)
    user.canAddListPhoneAgencies = userPermission?.includes(canAddListPhoneAgencies)
    user.canUpdateListPhoneAgencies = userPermission?.includes(canUpdateListPhoneAgencies)
    user.canPhoneAgenciesManagement = user.canGetListPhoneAgencies
        || user.canAddListPhoneAgencies
        || user.canUpdateListPhoneAgencies

    // Call Info
    user.canGetListCallInfo = userPermission?.includes(canGetListCallInfo)
    user.canAddListCallTelesale = userPermission?.includes(canAddListCallTelesale)
    user.canAddLogFeedback = userPermission?.includes(canAddLogFeedback)
    user.canUpdateListCallInfo = userPermission?.includes(canUpdateListCallInfo)
    user.canCallInfoManagement = user.canGetListCallInfo
        || user.canAddListCallTelesale
        || user.canAddLogFeedback
        || user.canUpdateListCallInfo

    // Call Log
    user.canGetListCallLog = userPermission?.includes(canGetListCallLog)
    user.canAddListCallLog = userPermission?.includes(canAddListCallLog)
    user.canUpdateListCallLog = userPermission?.includes(canUpdateListCallLog)
    user.canCallLogManagement = user.canGetListCallLog
        || user.canAddListCallLog
        || user.canUpdateListCallLog
}

const hasAnyAuthority = (functions: string[], hasAnyFunctions: string[]) => {
    if (_.isEmpty(functions)) {
        return false;
    }
    if (hasAnyFunctions.length === 0) {
        return true;
    }
    return hasAnyFunctions.some(auth => functions.includes(auth));
};
const PermUtils = {
    isAdmin,
    // Companies
    canGetListCompanies,
    canAddCompany,
    canUpdateCompany,
    canDeleteCompany,

    // Packages
    canGetListPackages,
    canAddPackage,
    canAddListPackages,
    canUpdatePackage,
    canDeletePackage,

    // Partner Packages
    canGetListPartnerPackages,
    canAddPartnerPackage,
    canAddListPartnerPackages,
    canDeletePartnerPackage,
    canGetListPackagesTkgDashboard,
    canGetListPackagesSponsor,
    canGetListPackagesConnection,

    // Telegrams
    canGetListTelegrams,
    canAddTelegram,
    canUpdateTelegram,
    canDeleteTelegram,

    // Transactions
    canGetListTransactions,
    canRenewTransaction,
    canAddTransaction,
    canConnectTransaction,

    // Users
    canGetListUsers,
    canAddUser,
    canUpdateUser,
    canDeleteUser,

    // Roles
    canGetListBasePermissions,
    canGetListRoles,
    canAddRole,
    canUpdateRole,
    canDeleteRole,

    // Sim Passports
    canGetListSimPassports,
    canUpdateSimPassport,

    // Mbh Topups
    canCheckBalanceTopup,
    canGetListOrderTopups,
    canGetListTransactionTopupsByOrderId,
    canCheckTransactionTopupOnResource,
    canCheckTransactionTopupOnAdapter,
    canUploadOrderTopupByExcel,
    canTopupByPhone,
    canTopupByOrderId,
    canUpdateStatusTransactionTopup,

    // Mbh Tkg Dashboard
    canGetDynamicPartnerOffer,
    canGetListOrderTkgDashboard,
    canGetListTransactionTkgDashboardByOrderId,
    canCheckTransactionTkgOnResource,
    canCheckTransactionTkgOnAdapter,
    canUploadOrderTkgByExcel,
    canTkgByPhone,
    canTkgByOrderId,
    canUpdateStatusTransactionTkgDashboard,

    // Data Sponsor
    canGetDynamicPartnerOfferDataSponsor,
    canGetListOrderDataSponsor,
    canGetListTransactionDataSponsorByOrderId,
    canCheckTransactionDataSponsorOnResource,
    canCheckTransactionDataSponsorOnAdapter,
    canUploadOrderDataSponsorByExcel,
    canDataSponsorByPhone,
    canDataSponsorByOrderId,
    canCheckWalletBalancePostpaid,
    canUpdateStatusTransactionDataSponsor,

    // User Partner
    canGetListUserPartners,
    canAddUserPartner,
    canUpdateUserPartner,
    canDeleteUserPartner,

    // Transaction Connection
    canGetListTransactionConnection,
    canAddListTransactionConnection,
    canUpdateListTransactionConnection,
    canAddTransactionConnectionBySyntax,
    canGetListTransactionConnectionExpire,
    canGetListTransactionConnectionView,
    canUpdateListSimTransactionConnection,
    canUpdatePhoneNumberTransactionConnection,
    canUpdateDataPackTransactionConnection,

    // Statistical Service
    canGetRevenueConnection,
    canGetRevenueDateConnection,
    canGetStatisticalTypeConnection,
    canGetStatisticalDateConnection,
    canGetStatisticalPackDurationConnection,
    canGetStatisticalTypeExpiration,
    canGetStatisticalDateExpiration,
    canGetStatisticalDateExtension,
    canRecalculateStatistics,
    canGetStatisticalDateCalling,
    canGetStatisticalTypeCalling,

    // Customer Resource
    canGetListCustomerResource,
    canAddListCustomerResource,
    canUpdateListCustomerResource,

    // Phone Agency
    canGetListPhoneAgencies,
    canAddListPhoneAgencies,
    canUpdateListPhoneAgencies,

    // Call Info
    canGetListCallInfo,
    canAddListCallTelesale,
    canAddLogFeedback,
    canUpdateListCallInfo,

    // Call Log
    canGetListCallLog,
    canAddListCallLog,
    canUpdateListCallLog,

    setUserPermissions,
    hasAnyAuthority,
}

export default PermUtils