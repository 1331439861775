import { useCallback, useEffect, useState } from "react"
import { useLoading } from "../../context/LoadingContext"
import { apiCheckBalanceTopup } from "../../api"
import { formatCurrency } from "../../utils/string-utils"
import { Button, Divider, Space, Tag } from "antd"
import { Line } from "react-chartjs-2"

export const renderOrderStatus = (state: string) => {
    const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
    if (state === 'COMPLETED') return <Tag style={style} color="#9b9b05">Hoàn thành</Tag>
    if (state === 'CANCELLED') return <Tag style={style} color="#c93c3c">Đã huỷ bỏ</Tag>
    if (state === 'CREATED') return <Tag style={style} color="#2faac6">Đã tạo</Tag>
    if (state === 'PROCESSING') return <Tag style={style} color="#1d9a46">Đang xử lý</Tag>
    return <Tag style={style} color="#ff6f00">Đang nạp excel</Tag>
}

export const renderStatus = (state: string) => {
    const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
    if (state === 'SUCCESS') return <Tag style={style} color="#9b9b05">Thành công</Tag>
    if (state === 'FAIL') return <Tag style={style} color="#c93c3c">Thất bại</Tag>
    if (state === 'ERROR') return <Tag style={style} color="#c93c3c">Lỗi</Tag>
    if (state === 'TIMEOUT') return <Tag style={style} color="#cb28df">Chưa rõ kết quả</Tag>
    if (state === 'PROCESSING') return <Tag style={style} color="#1d9a46">Đang xử lý</Tag>
    return <Tag style={style} color="#2faac6">Đã tạo</Tag>
}

export default function ManagementTopUpOverview() {
    const [balance, setBalance] = useState<any>()

    const { setIsLoading } = useLoading()

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await apiCheckBalanceTopup()
            if (resp.data != null) {
                setBalance(resp.data)
            } else {
                setBalance({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [65, 59, 80, 81, 56, 55, 40],
                borderColor: 'rgba(75,192,192,1)',
                backgroundColor: 'rgba(75,192,192,0.2)',
            },
        ],
    }

    return (
        <div>
            <Space>
                <h2>Số dư: {formatCurrency(balance?.balance || 0)}</h2>
                <Divider />
                <Button onClick={fetchData}>Làm mới</Button>
            </Space>
            <Line data={data} />
        </div>
    )
}
