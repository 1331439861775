import { useCallback, useEffect, useState } from 'react'
import { Button, Divider, Form, Input, Modal, Select, Space, TableProps, Upload, message } from 'antd'
import { MoneyCollectOutlined, UploadOutlined, EyeOutlined, SearchOutlined, UndoOutlined, LockOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { toastError, toastSuccess } from '../../utils/toast'
import { useLoading } from '../../context/LoadingContext'
import { formatCurrency } from '../../utils/string-utils'
import { apiGetListOrderDataSponsor, apiDataSponsorByOrderId, apiDataSponsorByPhone, apiUploadOrderDataSponsorByExcel, apiGetListPackagesSponsor, apiUpdateLockOrderDataSponsor } from '../../api'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { DETAIL_TRANSACTION, REGISTER_ADDON } from '../../assets/constants/route_path'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { orderStatusOptions } from '../../assets/constants/default_options'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'
import { renderOrderStatus } from '../quanlytopup'

export default function RegisterAddonOrder() {
    const [packTkgOptions, setPackTkgOptions] = useState([])
    const [orderTkg, setOrderTkg] = useState<any>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        status: '',
    })
    const [modalStatus, setModalStatus] = useState<{ data: any | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalLock, setModalLock] = useState<{ data: any | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalTkgPhone, setModalTkgPhone] = useState<boolean>(false)
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [modalUpload, setModalUpload] = useState<boolean>(false)
    const [fileExcel, setFileExcel] = useState<any>()
    const [fileList, setFileList] = useState([])
    const [descExcel, setDescExcel] = useState<any>()
    const [dataPack, setDataPack] = useState<string>()

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'orderId',
            title: 'Mã đơn hàng',
            dataIndex: 'orderId',
            render: orderId => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {orderId}
                </span>
            ),
        },
        {
            key: 'ownerBy',
            title: 'Nhân sự tạo đơn',
            dataIndex: 'ownerBy',
        },
        {
            key: 'description',
            title: 'Mô tả',
            dataIndex: 'description',
        },
        {
            key: 'totalPhoneNumber',
            title: 'Tổng số điện thoại',
            dataIndex: 'totalPhoneNumber',
        },
        {
            key: 'totalPrice',
            title: 'Tổng giá',
            dataIndex: 'totalPrice',
            render: (totalPrice) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(totalPrice)}
                </span>
            ),
        },
        {
            key: 'totalSuccess',
            title: 'Tổng thành công',
            dataIndex: 'totalSuccess',
        },
        {
            key: 'totalFail',
            title: 'Tổng thất bại',
            dataIndex: 'totalFail',
        },
        {
            key: 'reqTime',
            title: 'Thời gian tạo',
            dataIndex: 'reqTime',
            render: (reqTime) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(reqTime * 1000)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            )
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
            render: status => renderOrderStatus(status)
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: any) => (
                <Space>
                    {account.canGetListTransactionDataSponsorByOrderId && (
                        <Link to={REGISTER_ADDON + DETAIL_TRANSACTION + `?orderId=${data.orderId}`}>
                            <Button
                                icon={<EyeOutlined />}
                                style={{ background: '#289e45', color: 'white' }}
                            >
                                Chi tiết
                            </Button>
                        </Link>
                    )}
                    {account.canDataSponsorByOrderId && (
                        <Button
                            disabled={data.status !== 'CREATED' || data.isLocked === true}
                            onClick={() => setModalStatus({ data, isOpen: true })}
                            icon={<MoneyCollectOutlined />}
                            type='primary'
                        >
                            Nạp data sponsor
                        </Button>
                    )}
                    {account.canUpdateLockOrderDataSponsor && data.status === 'CREATED' && (
                        <Button
                            disabled={data.isLocked === true}
                            onClick={() => setModalLock({ data, isOpen: true })}
                            icon={<LockOutlined />}
                            type='primary'
                            danger
                        >
                        </Button>
                    )}
                </Space>
            ),
        },
    ]

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const respOrder = await apiGetListOrderDataSponsor({
                page: currentPage,
                size: pageSize,
                status: search.status,
            })
            if (respOrder.data === null) {
                setOrderTkg({})
                return
            }
            setOrderTkg(respOrder.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    const fetchPackOptions = useCallback(async () => {
        try {
            const resp = await apiGetListPackagesSponsor()
            const listPackages = resp.data?.partner_packages
            if (listPackages) {
                const listOptions = listPackages.map((item: any) => {
                    return { value: item.name, label: `${item.name} - ${formatCurrency(Number(item.price))}` }
                })
                setPackTkgOptions(listOptions)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        fetchData()
        fetchPackOptions()
    }, [fetchData, fetchPackOptions])

    const handleSearch = ({ status }: any) => {
        if (status) {
            setSearch({ status })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            status: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleTkgOrderId = async () => {
        try {
            setIsLoading(true)
            const resp = await apiDataSponsorByOrderId({ orderId: modalStatus.data.id })
            if (resp.status === 200) {
                toastSuccess(resp.data.message)
                fetchData()
                handleCloseModalTkgOrderId()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }
    const handleCloseModalTkgOrderId = () => {
        setModalStatus({ data: null, isOpen: false })
        setDataPack(undefined)
    }

    const handleTkgPhone = async () => {
        if (!phoneNumber || !dataPack) {
            toastError('Vui lòng nhập đầy đủ thông tin!')
            return
        }

        const regex = /^(0|84)?\d{9,10}$/
        if (!regex.test(phoneNumber.replaceAll(' ', ''))) {
            toastError('Vui lòng nhập số điện thoại hợp lệ!')
            return
        }

        try {
            setIsLoading(true)
            const resp = await apiDataSponsorByPhone({ phoneNumber: phoneNumber.trim(), dataPack })
            if (resp.status === 200) {
                setTimeout(() => {
                    toastSuccess(resp.data.message)
                    fetchData()
                    handleCloseModalTkgPhone()
                    setIsLoading(false)
                }, 5000)
            } else {
                toastError('Có lỗi xảy ra!')
                setIsLoading(false)
            }
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }
    const handleCloseModalTkgPhone = () => {
        setModalTkgPhone(false)
        setPhoneNumber('')
        setDataPack(undefined)
    }

    const handleUploadFile = async () => {
        if (!fileExcel || !descExcel) {
            toastError('Vui lòng nhập đầy đủ thông tin!')
            return
        }

        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.append("file", fileExcel.file)
            const resp = await apiUploadOrderDataSponsorByExcel(formData, descExcel)
            if (resp.status === 200) {
                toastSuccess('nạp data sponsor đang thực hiện! Vui lòng kiểm tra kết quả sau ít phút')
                fetchData()
                handleCloseModalUploadFile()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }
    const handleCloseModalUploadFile = () => {
        setModalUpload(false)
        setFileExcel({})
        setDescExcel('')
    }
    const checkUploadFile = (file: any) => {
        const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'
        if (!isExcel) {
            message.error('Vui lòng upload file dạng excel!')
            return Upload.LIST_IGNORE
        }
        return false
    }
    const handleChangeFile = (value: any) => {
        setFileList(value.fileList)
        setFileExcel(value)
    }

    const handleLock = async () => {
        try {
            setIsLoading(true)
            await apiUpdateLockOrderDataSponsor({
                orderId: modalLock.data?.id,
                isLocked: true,
                status: 'CANCELLED',
            })
            fetchData()
            setModalLock({ isOpen: false, data: null })
            toastSuccess('Khoá đơn hàng thành công!')
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <Form form={formSearch} onFinish={handleSearch}>
                <Space style={{ display: 'flex', alignItems: 'start' }}>
                    <Form.Item name='status' label='Trạng thái'>
                        <Select
                            style={{ width: 170 }}
                            showSearch
                            placeholder="Chọn trạng thái"
                            options={orderStatusOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Space>
                        <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </Space>
            </Form>
            <div style={{ textAlign: 'end', marginBottom: 20 }}>
                <Space>
                    {account.canDataSponsorByPhone && (
                        <Button onClick={() => setModalTkgPhone(true)} type='dashed'>Nạp data sponsor</Button>
                    )}
                    {account.canUploadOrderDataSponsorByExcel && (
                        <Button onClick={() => setModalUpload(true)} type='primary'>Import đơn hàng (File Excel)</Button>
                    )}
                </Space>
            </div>
            <CustomTable
                namePage='đơn hàng'
                columns={columns}
                dataSource={orderTkg.orders}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={orderTkg.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Nạp data sponsor' open={modalStatus.isOpen}
                onCancel={handleCloseModalTkgOrderId}
                footer={[
                    <Button onClick={handleTkgOrderId}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalTkgOrderId}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Nạp data sponsor cho đơn hàng <b>{modalStatus.data?.orderId}</b></p>
                <Divider />
            </Modal>

            <Modal title='Nạp data sponsor' open={modalTkgPhone}
                onCancel={handleCloseModalTkgPhone}
                footer={[
                    <Button onClick={handleTkgPhone}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalTkgPhone}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Nhập số điện thoại</p>
                <Input value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} style={{ width: '100%' }} />
                <p>Chọn gói cước</p>
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    placeholder="Chọn gói cước"
                    options={packTkgOptions}
                    value={dataPack}
                    onChange={value => setDataPack(value)}
                    filterOption={filterOptionByLabel}
                />
                <Divider />
            </Modal>

            <Modal title='Upload' open={modalUpload}
                onCancel={handleCloseModalUploadFile}
                footer={[
                    <Button onClick={handleUploadFile}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalUploadFile}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Đăng tải File Excel</p>
                <Upload
                    maxCount={1}
                    fileList={fileList}
                    onChange={handleChangeFile}
                    beforeUpload={checkUploadFile}
                >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
                <p>Nhập mô tả</p>
                <Input value={descExcel} onChange={e => setDescExcel(e.target.value)} />
                <Divider />
                <p><b>Ghi chú</b></p>
                <p>Định dạng: Số điện thoại và mã gói cước</p>
                <p>Tên sheet: Sheet1</p>
                <p>Tối đa: 200 số điện thoại</p>
                <Divider />
            </Modal>

            <Modal title='Khoá đơn hàng' open={modalLock.isOpen}
                onCancel={() => setModalLock({ isOpen: false, data: null })}
                footer={[
                    <Button onClick={handleLock} type='primary' danger>
                        Xác nhận
                    </Button>,
                    <Button onClick={() => setModalLock({ isOpen: false, data: null })}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Bạn có chắc muốn khoá đơn hàng <b>{modalLock.data?.orderId}</b></p>
                <Divider />
            </Modal>
        </div>
    )
}
