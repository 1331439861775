import { Line } from 'react-chartjs-2'
import { colorPalette } from '../../utils/color-utils';

export default function ChartExtendDay({ record, filtered, title }: any) {
    const labels = record[0]?.days?.map((item: any) => item.day)

    const datasets = record.map((item: any, index: any) => ({
        label: item.month,
        data: item.days?.map((day: any) => day.sum),
        borderWidth: 2,
        datalabels: {
            color: colorPalette[index],
        },
        hidden: record.length - index > 1,
    }))

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            tooltip: {
                enabled: true,
            },
            title: {
                display: true,
                text: title,
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'top',
                formatter: (value: any) => value === 0 ? '' : value,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    } as any

    return (
        <Line data={{ labels, datasets }} options={options} />
    )
}
