import { useCallback, useEffect, useState } from 'react'
import { Button, Divider, Flex, Form, Modal, Progress, Select, Space, Table, TableProps } from 'antd'
import { EditOutlined, SearchOutlined, UndoOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { toastError, toastSuccess } from '../../utils/toast'
import { useLoading } from '../../context/LoadingContext'
import { apiGetListTransactionDataSponsorByOrderId, apiCheckTransactionDataSponsorOnAdapter, apiUpdateStatusTransactionDataSponsor } from '../../api'
import moment from 'moment'
import * as XLSX from "xlsx"
import { saveAs } from "file-saver"
import ButtonBack from '../../components/button/ButtonBack'
import { formatCurrency } from '../../utils/string-utils'
import { useSearchParams } from 'react-router-dom'
import { mbhStatusOptions } from '../../assets/constants/default_options'
import { filterOptionByLabel } from '../../utils/filter-utils'
import ModalView from './ModalView'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'
import { renderStatus } from '../quanlytopup'

export default function RegisterAddonDetailTransaction() {
    const [tranTkg, setTranTkg] = useState<any>()
    const [progessPercent, setProgessPercent] = useState<number>(0)
    const [listTran, setListTran] = useState<any>([])
    const [listTranDescOption, setListTranDescOption] = useState<any>([])
    const [listExport, setListExport] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        status: '',
    })
    const [searchExport, setSearchExport] = useState({
        status: '',
        description: 'ALL',
    })
    const [modalView, setModalView] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalCheckAdapter, setModalCheckAdapter] = useState<{ data: any | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [dataCheckAdapter, setDataCheckAdapter] = useState<any>({})
    const [isOpenModalDownload, setIsOpenModalDownload] = useState<boolean>(false)
    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()
    const [searchParams] = useSearchParams()
    const orderId = searchParams.get('orderId')

    const columns: TableProps<any>['columns'] = [
        {
            key: 'tranId',
            title: 'Mã giao dịch',
            dataIndex: 'tranId',
            render: tranId => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {tranId}
                </span>
            ),
        },
        {
            key: 'customer',
            title: 'Số điện thoại',
            dataIndex: 'customer',
        },
        {
            key: 'dataPack',
            title: 'Tên gói cước',
            dataIndex: 'dataPack',
        },
        {
            key: 'dataType',
            title: 'Loại gói cước',
            dataIndex: 'dataType',
            render: dataType => dataType || 'ADDON',
        },
        {
            key: 'price',
            title: 'Giá',
            dataIndex: 'price',
            render: (price) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'updatedAt',
            title: 'Cập nhật',
            dataIndex: 'updatedAt',
            render: (updatedAt) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(updatedAt)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            )
        },
        {
            key: 'reqTime',
            title: 'Thời gian tạo',
            dataIndex: 'reqTime',
            render: (reqTime) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(reqTime * 1000)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            )
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'return',
            render: status => renderStatus(status)
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: any) => (
                <Space>
                    <Button
                        onClick={() => setModalView({ data, isOpen: true })}
                        icon={<EyeOutlined />}
                        style={{ background: '#289e45', color: 'white' }}
                    >
                        Chi tiết
                    </Button>
                    {account.canCheckTransactionDataSponsorOnAdapter && (
                        <Button
                            onClick={() => setModalCheckAdapter({ data, isOpen: true })}
                            icon={<EditOutlined />}
                            type='primary'
                        >
                            Kiểm tra
                        </Button>
                    )}
                </Space>
            ),
        },
    ]

    const exportColumns: TableProps<any>['columns'] = [
        {
            key: 'tranId',
            title: 'Mã giao dịch',
            dataIndex: 'tranId',
            render: tranId => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {tranId}
                </span>
            ),
        },
        {
            key: 'customer',
            title: 'Số điện thoại',
            dataIndex: 'customer',
        },
        {
            key: 'dataPack',
            title: 'Gói cước',
            dataIndex: 'dataPack',
        },
        {
            key: 'description',
            title: 'Mô tả',
            dataIndex: 'description',
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'return',
            render: status => renderStatus(status)
        },
    ]

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await apiGetListTransactionDataSponsorByOrderId({
                orderId: orderId,
                page: currentPage,
                size: pageSize,
                status: search.status,
            })
            if (resp.data != null) {
                setTranTkg(resp.data)
                const respFull = await apiGetListTransactionDataSponsorByOrderId({
                    orderId: orderId,
                    page: defaultParams.PAGE,
                    size: defaultParams.MAX_LIMIT,
                })
                if (respFull.data != null) {
                    let totalProgess = 0
                    let listTranDesc = [] as any
                    respFull.data.trans?.map((item: any) => {
                        if (item.return === 'SUCCESS' || item.return === 'FAIL' || item.return === 'ERROR') {
                            totalProgess++
                        }
                        if (!listTranDesc.includes(item.description)) {
                            listTranDesc.push(item.description)
                        }
                    })
                    if (totalProgess > 0) {
                        const percent = Number(totalProgess / respFull.data.total * 100).toFixed(2)
                        setProgessPercent(Number(percent))
                    }
                    setListTran(respFull.data.trans)
                    setListTranDescOption(listTranDesc.map((item: any) => ({ label: item || '(Trống)', value: item })))
                }
            } else {
                setTranTkg({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading, currentPage, pageSize, orderId, search])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSearch = ({ status }: any) => {
        if (status) {
            setSearch({ status })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            status: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleCheckAdapter = async () => {
        try {
            setIsLoading(true)
            const resp = await apiCheckTransactionDataSponsorOnAdapter({
                tranId: modalCheckAdapter.data?.tranId,
            })
            const respData = resp.data?.data?.listTrans[0]
            if (resp.status === 200 && respData !== undefined) {
                if (!respData.description) {
                    respData.description = 'Không có kết quả trả về'
                }
                setDataCheckAdapter(respData)
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    const handleCloseModalCheckAdapter = () => {
        setModalCheckAdapter({ data: null, isOpen: false })
        setDataCheckAdapter({})
    }

    useEffect(() => {
        if (listTran.length === 0) return
        let dataExport = listTran
        if (searchExport.description !== 'ALL') {
            dataExport = dataExport.filter((item: any) => item.description === searchExport.description)
        }
        if (searchExport.status) {
            dataExport = dataExport.filter((item: any) => item.return === searchExport.status)
        }
        setListExport(dataExport)
    }, [searchExport, listTran])

    const handleDownloadExcel = async () => {
        try {
            setIsLoading(true)
            const dataExcel = listExport?.map((item: any) => ({
                id: item.id,
                tranId: item.tranId,
                customer: item.customer,
                packageName: item.packageName,
                dataPack: item.dataPack,
                price: item.price,
                cpCode: item.cpCode,
                detailCode: item.detailCode,
                description: item.description,
            }))
            const ws = XLSX.utils.json_to_sheet(dataExcel)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
            const blob = new Blob([excelBuffer], { type: "application/octet-stream" })
            saveAs(blob, `${orderId}${searchExport.status && `-${searchExport.status}`}.xlsx`)
            setIsOpenModalDownload(false)
            toastSuccess('Đã tải xuống!')
        } catch (error) {
            console.log(error)
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpdateStatus = async () => {
        try {
            setIsLoading(true)
            await apiUpdateStatusTransactionDataSponsor({
                transaction_id: modalCheckAdapter.data?.tranId,
                year: modalCheckAdapter.data?.year,
                month: modalCheckAdapter.data?.month,
                status: dataCheckAdapter.return,
                description: dataCheckAdapter.description,
            })
            fetchData()
            handleCloseModalCheckAdapter()
            toastSuccess('Cập nhật trạng thái thành công!')
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <ButtonBack />
            <h2>Mã đơn hàng: {orderId}</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Form form={formSearch} onFinish={handleSearch}>
                    <Space style={{ display: 'flex', alignItems: 'start' }}>
                        <Form.Item name='status' label='Trạng thái'>
                            <Select
                                style={{ width: 170 }}
                                showSearch
                                placeholder="Chọn trạng thái"
                                options={mbhStatusOptions}
                                filterOption={filterOptionByLabel}
                            />
                        </Form.Item>
                        <Space>
                            <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                            <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                        </Space>
                    </Space>
                </Form>
                <Button onClick={() => setIsOpenModalDownload(true)} icon={<DownloadOutlined />}>Tải xuống (File Excel)</Button>
            </div>
            <Space style={{ marginBottom: 20 }}>
                Đã xử lý: <Flex gap="small" vertical>
                    <Progress style={{ width: 300 }} percent={progessPercent} status="active" />
                </Flex>
            </Space>
            <CustomTable
                namePage='giao dịch'
                columns={columns}
                dataSource={tranTkg?.trans}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={tranTkg?.total || tranTkg?.trans?.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Kiểm tra giao dịch' open={modalCheckAdapter.isOpen}
                onCancel={handleCloseModalCheckAdapter}
                footer={[]}>
                <Divider />
                <p>Kiểm tra giao dịch <b>{modalCheckAdapter.data?.tranId}</b></p>
                {dataCheckAdapter?.description && <b>Kết quả: {dataCheckAdapter.description}</b>}
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        {(account?.canUpdateStatusTransactionDataSponsor
                            && (dataCheckAdapter?.return === 'SUCCESS' || dataCheckAdapter?.return === 'FAIL')
                            && modalCheckAdapter.data?.return === 'PROCESSING')
                            && (
                                <Button onClick={handleUpdateStatus}
                                    style={{ background: '#b07a16', color: 'white' }}>
                                    Cập nhật
                                </Button>
                            )}
                    </div>
                    <Space>
                        <Button onClick={handleCheckAdapter}
                            style={{ background: '#008965', color: 'white' }}>
                            Xác nhận
                        </Button>
                        <Button onClick={handleCloseModalCheckAdapter}
                            style={{ background: 'gray', color: 'white' }}>
                            Đóng
                        </Button>
                    </Space>
                </div>
            </Modal>
            <ModalView
                modalView={modalView}
                setModalView={setModalView}
            />
            <Modal
                className='modal-export'
                title='Tải xuống (File Excel)'
                open={isOpenModalDownload}
                footer={[]}
                onCancel={() => setIsOpenModalDownload(false)}>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <div style={{ display: 'flex', gap: 20, marginBottom: 10 }}>
                    <Space>
                        <span>Trạng thái:</span>
                        <Select
                            onChange={value => setSearchExport({ ...searchExport, status: value })}
                            style={{ minWidth: 170 }}
                            defaultValue={''}
                            options={[
                                { label: 'Tất cả', value: '' },
                                ...mbhStatusOptions,
                            ]}
                        />
                    </Space>
                    <Space>
                        <span>Mô tả:</span>
                        <Select
                            onChange={value => setSearchExport({ ...searchExport, description: value })}
                            style={{ minWidth: 170 }}
                            defaultValue={'ALL'}
                            options={[
                                { label: 'Tất cả', value: 'ALL' },
                                ...listTranDescOption,
                            ]}
                        />
                    </Space>
                </div>
                <Table
                    size='small'
                    rowKey={(_, index: any) => index}
                    style={{ overflow: 'auto' }}
                    columns={exportColumns}
                    dataSource={listExport}
                    pagination={{ pageSize: 10 }}
                />
                <div style={{ textAlign: 'right' }}>
                    <Button disabled={listExport?.length === 0} onClick={handleDownloadExcel} type='primary' icon={<DownloadOutlined />}>
                        Tải xuống ({listExport?.length})
                    </Button>
                </div>
            </Modal>
        </div>
    )
}
