import { Doughnut } from 'react-chartjs-2'
import { formatCurrency, formatShortCurrency } from '../../utils/string-utils'

export default function ChartRevenue({ data, title }: any) {
    const dataPacks = data.dataPacks?.filter((item: any) => item.sum > 0).map((item: any) => {
        const percent = item.sum / data.total * 100
        return {
            dataPack: item.dataPack,
            sum: item.sum,
            hidden: percent < 5,
        }
    }).sort((a: any, b: any) => a.sum - b.sum)


    const labels = dataPacks.map((item: any) => item.dataPack)

    const datasets = [{ data: dataPacks?.map((item: any) => item.sum) }]

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            tooltip: {
                callbacks: {
                    label: (context: any) => formatCurrency(context.raw),
                },
            },
            title: {
                display: true,
                text: title,
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                formatter: (_: any, value: any) => {
                    const sum = dataPacks[value.dataIndex].sum
                    const hidden = dataPacks[value.dataIndex].hidden
                    return hidden ? '' : formatShortCurrency(sum)
                },
                font: {
                    weight: 'bold',
                    size: 14,
                },
            },
        },
    } as any

    return (
        <Doughnut data={{ labels, datasets }} options={options} />
    )
}
