import { DatePicker, Form, ConfigProvider, Select, Space, Button, Tag, Card, Timeline, Pagination, Empty, Modal, Divider } from 'antd'
import { ExceptionOutlined, DashboardOutlined, DollarOutlined, SearchOutlined, UndoOutlined, DownloadOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { formatCurrency, formatNumber } from '../../utils/string-utils'
import dayjs from 'dayjs'
import vi_VN from 'antd/es/locale/vi_VN'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import 'dayjs/locale/vi'
import { useCallback, useEffect, useRef, useState } from 'react'
import ChartMonth from '../../components/chart/ChartMonth'
import ChartExtendByMonth from '../../components/chart/ChartExtendByMonth'
import { useLoading } from '../../context/LoadingContext'
import {
    apiAddLogFeedback,
    apiGetListCallInfo,
    apiGetListCompanies,
    apiGetListPackagesConnection,
    apiGetRevenueConnection,
    apiGetStatisticalDateConnection,
    apiGetStatisticalDateExpiration,
} from '../../api'
import defaultParams, {
    REVENUE_CONNECTION_AMOUNT_TYPE_PROFIT,
    STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_FILTER,
    STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD,
    TRANSACTION_CONNECTION_TYPE_CONNECTION,
    TRANSACTION_CONNECTION_TYPE_EXTENSION,
} from '../../assets/constants/default_params'
import { ICompany } from '../../models/company.modal'
import { filterOptionByLabel } from '../../utils/filter-utils'
import ChartExtendMonth from '../../components/chart/ChartExtendMonth'
import ChartExtendDay from '../../components/chart/ChartExtendDay'
import { IPartnerPackage } from '../../models/package.modal'
import { toastError, toastSuccess } from '../../utils/toast'
import { partnerExtensionCommentOptions, transactionConnectionTypeOptions } from '../../assets/constants/default_options'
import moment from 'moment'
import ReactQuill from 'react-quill'
import domtoimage from "dom-to-image"
import { useReactToPrint } from "react-to-print"
import { authSelector } from '../../redux/slices/authSlice'
import { useSelector } from 'react-redux'

const { RangePicker } = DatePicker

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.locale('vi')

export default function DashboardGiaHan() {
    const [totalExpired, setTotalExpired] = useState<any>(0)
    const [totalExtended, setTotalExtended] = useState<any>(0)
    const [totalProfitExtension, setTotalProfitExtension] = useState<number>(0)
    const [totalExpiredRecord, setTotalExpiredRecord] = useState<any>([])
    const [totalExpiredFilter, setTotalExpiredFilter] = useState<any>([])
    const [totalExtendedRecord, setTotalExtendedRecord] = useState<any>([])
    const [listComments, setListComments] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.MIN_LIMIT)
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [partnerPackageOptions, setPartnerPackageOptions] = useState<{ label: string, value: Number }[]>([])
    const [cpCode, setCpCode] = useState<string>('')
    const [width, setWidth] = useState(window.innerWidth)
    const [search, setSearch] = useState<any>({})
    const [defaultSearch, setDefaultSearch] = useState<any>()
    const [formSearch] = Form.useForm()
    const [formCreateComment] = Form.useForm()
    const { setIsLoading } = useLoading()
    const captureRef = useRef(null)
    const [isOpenModalExport, setIsOpenModalExport] = useState(false)
    const [isPreview, setIsPreview] = useState(false)
    const [value1, setValue1] = useState('')
    const [value2, setValue2] = useState('')
    const [value3, setValue3] = useState('')
    const [value4, setValue4] = useState('')
    const [value5, setValue5] = useState('')
    const { account } = useSelector(authSelector)

    const renderType = (type: string, date: string) => {
        const style: object = { textAlign: 'center', fontSize: 14, padding: '2px 8px' }
        if (type === 'EXTENSION-RECORD') return <Tag style={style} color="green"><b>Đấu nối</b>: ({date})</Tag>
        if (type === 'EXTENSION-PROFIT') return <Tag style={style} color="purple"><b>Lợi nhuận</b>: ({date})</Tag>
        if (type === 'EXTENSION-PERIOD') return <Tag style={style} color="blue"><b>Tỷ lệ gói</b>: ({date})</Tag>
        if (type === 'EXTENSION-SUMMARY') return <Tag style={style} color="red"><b>Tổng kết</b>: ({date})</Tag>
        return <Tag style={style} color="orange">{date}</Tag>
    }

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    const fetchCompanyOptions = useCallback(async () => {
        try {
            const resp = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resp.data?.companies) {
                const compOptions = resp.data.companies
                    .map((item: ICompany) => ({
                        label: item.name,
                        value: item.code,
                    }))
                setCompanyOptions(compOptions)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchPartnerPackages = useCallback(async () => {
        if (!cpCode) return
        try {
            const resp = await apiGetListPackagesConnection(cpCode)
            if (resp.data?.partner_packages === null) {
                return
            }
            const options = resp.data.partner_packages
                .filter((item: any, index: any, self: any) => index === self.findIndex((pack: any) => pack.code === item.code))
                .map((item: IPartnerPackage) => ({ label: item.name, value: item.code }))
            setPartnerPackageOptions(options)
        } catch (error) {
            console.log(error)
        }
    }, [cpCode])

    const fetchStatistics = useCallback(async () => {
        try {
            setIsLoading(true)
            const [respProfit, respExpiredRecord, respExpiredFilter, respExtendedRecord] = await Promise.all([
                apiGetRevenueConnection({
                    amountType: REVENUE_CONNECTION_AMOUNT_TYPE_PROFIT,
                    connectionType: TRANSACTION_CONNECTION_TYPE_EXTENSION,
                    ...search,
                }),
                apiGetStatisticalDateExpiration({
                    amountType: STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD,
                    ...search,
                }),
                apiGetStatisticalDateExpiration({
                    amountType: STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_FILTER,
                    ...search,
                }),
                apiGetStatisticalDateConnection({
                    amountType: STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD,
                    ...search,
                    connectionType: TRANSACTION_CONNECTION_TYPE_EXTENSION,
                }),
            ])
            if (respProfit.data?.total !== null) {
                setTotalProfitExtension(respProfit.data.total)
            } else {
                setTotalProfitExtension(0)
            }
            if (respExpiredRecord.data?.months != null) {
                setTotalExpired(respExpiredRecord.data?.total)
                setTotalExpiredRecord(respExpiredRecord.data.months)
            } else {
                setTotalExpired(0)
                setTotalExpiredRecord([])
            }
            if (respExpiredFilter.data?.months != null) {
                setTotalExpiredFilter(respExpiredFilter.data.months)
            } else {
                setTotalExpiredFilter([])
            }
            if (respExtendedRecord.data?.months != null) {
                setTotalExtended(respExtendedRecord.data?.total)
                setTotalExtendedRecord(respExtendedRecord.data.months)
            } else {
                setTotalExtended(0)
                setTotalExtendedRecord([])
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, search])

    const fetchComments = useCallback(async () => {
        if (!search.cpCode || !search.startDate || !search.endDate) return
        try {
            const startSearch = new Date(search.startDate)
            const endSearch = new Date(search.endDate)
            const { data } = await apiGetListCallInfo({ pk: `${search.cpCode}#FEEDBACK-COMMENT-EXTENSION` })
            const calls = data.calls?.filter((item: any) => {
                const [startDate, endDate] = item.sk?.split('#')[1]?.split('_')
                const start = new Date(startDate)
                const end = new Date(endDate)
                return start.getTime() >= startSearch.getTime() && end.getTime() <= endSearch.getTime()
            }).sort((a: any, b: any) => a.sk.split('#')[2] > b.sk.split('#')[2] ? -1 : 1) || []
            setListComments(calls)
        } catch (error) {
            console.log(error)
        }
    }, [search])

    useEffect(() => {
        const now = new Date()
        const year = now.getFullYear()
        const defaultParams = {
            cpCode: '',
            startDate: `${year}-01-01`,
            endDate: `${year}-12-31`,
        }
        setDefaultSearch(defaultParams)
        setSearch(defaultParams)
    }, [])

    useEffect(() => {
        if (defaultSearch) {
            fetchComments()
            fetchStatistics()
        }
    }, [fetchStatistics, fetchComments, defaultSearch])

    useEffect(() => {
        if (companyOptions.length === 0) {
            fetchCompanyOptions()
        }
        fetchPartnerPackages()
    }, [fetchCompanyOptions, fetchPartnerPackages, companyOptions.length])

    const handleSearch = (values: any) => {
        const rangeDate = values?.dateExpired
        if (rangeDate) {
            const startDate = rangeDate[0] ? rangeDate[0]?.format('YYYY-MM-DD') : null
            const endDate = rangeDate[1] ? rangeDate[1]?.format('YYYY-MM-DD') : null
            values.startDate = startDate
            values.endDate = endDate
        }

        setSearch({
            cpCode: values.cpCode,
            dataPack: values.dataPack,
            connectionType: values.type,
            numberDay: values.numberDay,
            startDate: values.startDate || defaultSearch.startDate,
            endDate: values.endDate || defaultSearch.endDate,
        })
    }

    const handleClearSearch = () => {
        setCpCode('')
        setSearch(defaultSearch)
        formSearch.resetFields()
    }

    const handleCreateComment = async (values: any) => {
        try {
            setIsLoading(true)
            if (!values.result?.replace(/<[^>]+>/g, "").trim()) return toastError('Vui lòng nhập nhật xét!')
            await apiAddLogFeedback({
                call: {
                    pk: `${cpCode}#FEEDBACK-COMMENT-EXTENSION`,
                    sk: `${values.type}#${search.startDate}_${search.endDate}#${moment().format('YYYY-MM-DD HH:mm:ss')}`,
                    result: values.result,
                }
            })
            formCreateComment.resetFields()
            fetchComments()
            toastSuccess('Thêm nhận xét thành công!')
        } catch (error) {
            toastError('Có lỗi xảy ra!')
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleExportImage = async () => {
        if (captureRef.current) {
            const dataUrl = await domtoimage.toPng(captureRef.current)
            const link = document.createElement("a")
            link.href = dataUrl
            link.download = `dashboard_gia-han-${search.startDate}_${search.endDate}.png`
            link.click()
        }
    }

    const handleExportPDF = useReactToPrint({
        contentRef: captureRef,
        documentTitle: `dashboard_gia-han-${search.startDate}_${search.endDate}`,
    })

    return (
        <div>
            <div style={{ background: 'white', padding: 10 }}>
                <Form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'start',
                        alignItems: 'center',
                        marginBottom: 20,
                    }}
                    form={formSearch} onFinish={handleSearch} id="searchForm" layout="vertical" autoComplete="off">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        flexWrap: 'wrap',
                        gap: 20,
                        width: '100%',
                    }}>
                        <Form.Item name="cpCode" label={<b>Đối tác</b>}>
                            <Select
                                onChange={value => {
                                    formSearch.setFieldValue('dataPack', '')
                                    setCpCode(value)
                                }}
                                style={{ minWidth: 170 }}
                                showSearch
                                placeholder="Chọn đối tác"
                                defaultValue={''}
                                options={[
                                    { label: 'Tất cả', value: '' },
                                    ...companyOptions,
                                ]}
                                filterOption={filterOptionByLabel}
                            />
                        </Form.Item>
                        {defaultSearch && (
                            <ConfigProvider locale={vi_VN}>
                                <Form.Item name="dateExpired" label="Ngày hết hạn">
                                    <RangePicker
                                        onChange={value => formCreateComment.setFieldValue('rangeDate', value)}
                                        defaultValue={[dayjs(defaultSearch.startDate), dayjs(defaultSearch.endDate)]}
                                        format="YYYY-MM-DD"
                                        picker="date" />
                                </Form.Item>
                            </ConfigProvider>
                        )}
                        <Form.Item name="dataPack" label="Gói cước">
                            <Select
                                disabled
                                showSearch
                                style={{ width: 170 }}
                                defaultValue={''}
                                options={[{ label: 'Tất cả', value: '' }, ...partnerPackageOptions]}
                                filterOption={filterOptionByLabel}
                            />
                        </Form.Item>
                        <Form.Item name="type" label="Phân loại">
                            <Select
                                disabled
                                style={{ width: 170 }}
                                defaultValue={''}
                                options={[
                                    { label: 'Tất cả', value: '' },
                                    { label: 'Đấu mới', value: TRANSACTION_CONNECTION_TYPE_CONNECTION },
                                    { label: 'Gia hạn', value: TRANSACTION_CONNECTION_TYPE_EXTENSION },
                                ]} />
                        </Form.Item>
                        <Form.Item name="numberDay" label="Chu kỳ">
                            <Select
                                disabled
                                style={{ width: 170 }}
                                defaultValue=''
                                options={[
                                    { label: 'Tất cả', value: '' },
                                    { label: '1 tháng', value: 30 },
                                    { label: '6 tháng', value: 180 },
                                    { label: '12 tháng', value: 360 },
                                    { label: '24 tháng', value: 720 },
                                ]} />
                        </Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                            <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                        </Space>
                    </div>
                </Form>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 40, marginBottom: 20 }}>
                    <div style={{
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 700,
                        border: '2px dashed #ec4969',
                        padding: '8px 20px',
                        borderRadius: 10,
                    }}>
                        <ExceptionOutlined /> SL sim hết hạn: <span style={{
                            fontSize: 20,
                            color: '#ec4969',
                        }}>{formatNumber(totalExpired)}</span>
                    </div>
                    <div style={{
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 700,
                        border: '2px dashed #ec4969',
                        padding: '8px 20px',
                        borderRadius: 10,
                    }}>
                        <DashboardOutlined /> Gia hạn: <span style={{
                            fontSize: 20,
                            color: '#ec4969',
                        }}>{formatNumber(totalExtended)}</span>
                    </div>
                    <div style={{
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 700,
                        border: '2px dashed #ec4969',
                        padding: '8px 20px',
                        borderRadius: 10,
                    }}>
                        <DollarOutlined /> Lợi nhuận từ GH: <span style={{
                            fontSize: 20,
                            color: '#ec4969',
                        }}>{formatCurrency(totalProfitExtension)}</span>
                    </div>
                </div>
                <div style={{ display: 'flex', flexWrap: width < 1024 ? 'wrap' : 'nowrap', marginBottom: 20 }}>
                    {totalExtendedRecord.length > 0 && totalExpiredRecord.length > 0 && totalExpiredFilter.length > 0 && (
                        <div style={{ flex: 1 }}>
                            <ChartExtendByMonth
                                extended={totalExtendedRecord}
                                expired={totalExpiredRecord}
                                filtered={totalExpiredFilter}
                            />
                        </div>
                    )}
                    {totalExpiredRecord.length > 0 && (
                        <div style={{ flex: 1 }}>
                            <ChartExtendMonth
                                record={totalExpiredRecord}
                                filtered={totalExpiredFilter}
                                title='Số lượng sim cần gia hạn theo tháng'
                                labelRecord='SL sim cần GH'
                                labelFilter='SL sim cần GH có DL'
                            />
                        </div>
                    )}
                </div>
                <div style={{ display: 'flex', flexWrap: width < 1024 ? 'wrap' : 'nowrap', marginBottom: 20 }}>
                    {totalExpiredRecord.length > 0 && (
                        <div style={{ flex: 1 }}>
                            <ChartExtendDay
                                record={totalExpiredRecord}
                                filtered={totalExpiredFilter}
                                title='SL sim hết hạn theo ngày'
                            />
                        </div>
                    )}
                    {totalExtendedRecord.length > 0 && (
                        <div style={{ flex: 1 }}>
                            <ChartMonth
                                data={totalExtendedRecord}
                                title='Số lượng sim gia hạn theo tháng'
                                label='SL sim đã GH'
                            />
                        </div>
                    )}
                </div>
                {search.cpCode && (
                    <Card title="Nhận xét">
                        {listComments.length > 0 ? (
                            <Timeline>
                                {listComments.slice((currentPage - 1) * pageSize, pageSize * currentPage).map((item: any, index: any) => (
                                    <Timeline.Item key={index} dot={<div style={{
                                        width: 8,
                                        height: 8,
                                        backgroundColor: "blue",
                                        borderRadius: "50%",
                                    }} />}>
                                        <div style={{ display: "flex", gap: 20 }}>
                                            <div style={{ width: 120 }}>
                                                <b>{item.owner_by}</b>
                                                <div>{item.sk?.split('#')[2]}</div>
                                            </div>
                                            <div style={{ flex: 1, borderLeft: '2px solid grey', paddingLeft: 20 }}>
                                                {renderType(item.sk?.split('#')[0], item.sk?.split('#')[1])}
                                                <ReactQuill
                                                    theme='bubble'
                                                    readOnly
                                                    value={item.result}
                                                />
                                            </div>
                                        </div>
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        ) : <Empty />}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                            <div>Tổng {listComments.length} nhận xét</div>
                            <Pagination
                                showSizeChanger
                                pageSize={pageSize}
                                onShowSizeChange={(_current, size) => setPageSize(size)}
                                total={listComments.length}
                                current={currentPage}
                                onChange={activePage => setCurrentPage(activePage)}
                            />
                        </div>
                    </Card>
                )}
            </div>
            {search.cpCode && (
                <div style={{ background: 'white', padding: 10 }}>
                    <Card style={{ marginTop: 20 }} type="inner" title="Thêm nhận xét">
                        <Form form={formCreateComment} onFinish={handleCreateComment} layout="vertical">
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                                <Form.Item name='type' label='Loại nhận xét'>
                                    <Select
                                        style={{ minWidth: 170 }}
                                        showSearch
                                        defaultValue={'EXTENSION-OTHER'}
                                        options={partnerExtensionCommentOptions}
                                        filterOption={filterOptionByLabel}
                                    />
                                </Form.Item>
                                <div style={{ flex: 1, maxWidth: 1000, minWidth: 360 }}>
                                    <Form.Item name='result' label='Nội dung'>
                                        <ReactQuill
                                            placeholder='Viết nhận xét...'
                                            theme="snow"
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <Space>
                                    <Button type='primary' htmlType='submit'>Thêm</Button>
                                    <Button type='dashed' htmlType='reset'>Làm mới</Button>
                                </Space>
                            </div>
                        </Form>
                    </Card>
                    <div style={{ textAlign: 'right', marginTop: 20 }}>
                        <Button onClick={() => setIsOpenModalExport(true)} icon={<DownloadOutlined />} type='dashed'>Tải xuống</Button>
                    </div>
                    <Modal
                        className='modal-export'
                        open={isOpenModalExport}
                        onCancel={() => {
                            setIsOpenModalExport(false)
                        }}
                        footer={[
                            <Button
                                onClick={() => setIsPreview(!isPreview)}
                                icon={isPreview ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                type='dashed'
                            >
                                {isPreview ? 'Tuỳ chỉnh' : 'Xem trước'}
                            </Button>,
                            <Button
                                disabled={!isPreview}
                                onClick={handleExportImage}
                                icon={<DownloadOutlined />}
                                type='primary'
                            >
                                Hình ảnh
                            </Button>,
                            <Button
                                disabled={!isPreview}
                                onClick={() => handleExportPDF()} icon={<DownloadOutlined />}
                                type='primary'
                            >
                                PDF
                            </Button>
                        ]}
                    >
                        <div style={{ padding: 20, background: 'white' }} ref={captureRef}>
                            <h1 style={{ textAlign: 'center', marginTop: 40 }}>
                                Thống kê gia hạn
                            </h1>
                            <div style={{ textAlign: 'center', marginTop: 20 }}>
                                <Space>
                                    <Card type='inner' size='small' title={(
                                        <h2 style={{ textAlign: 'center', margin: 0 }}>
                                            Đối tác: {companyOptions.find(item => item.value === search.cpCode)?.label}
                                        </h2>
                                    )}>
                                        Thời gian: {search.startDate} {search.endDate}
                                        <div style={{ textAlign: 'center', marginTop: 10 }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                                                <Card size='small' type='inner' title='SL sim hết hạn'>
                                                    {formatNumber(totalExpired)}
                                                </Card>
                                                <Card size='small' type='inner' title='Gia hạn'>
                                                    {formatNumber(totalExtended)}
                                                </Card>
                                                <Card size='small' type='inner' title='Lợi nhuận từ GH'>
                                                    {formatCurrency(totalProfitExtension)}
                                                </Card>
                                            </div>
                                        </div>
                                    </Card>
                                </Space>
                                {search.dataPack && <p>Mã gói cước: {search.dataPack}</p>}
                                {search.connectionType && <p>Loại gói cước: {transactionConnectionTypeOptions.find((item: any) => item.value === search.connectionType)?.label}</p>}
                                {search.numberDay && <p>Chu kỳ gói cước: {search.numberDay}</p>}
                            </div>
                            <div style={{ marginTop: 40 }}>
                                {totalExtendedRecord.length > 0 && totalExpiredRecord.length > 0 && totalExpiredFilter.length > 0 && (
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }}>
                                        <div style={{ width: '80%' }}>
                                            <ChartExtendByMonth
                                                extended={totalExtendedRecord}
                                                expired={totalExpiredRecord}
                                                filtered={totalExpiredFilter}
                                            />
                                            <ReactQuill
                                                key={isPreview ? "preview-mode" : "edit-mode"}
                                                value={value1}
                                                onChange={setValue1}
                                                theme={isPreview ? "bubble" : "snow"}
                                                readOnly={isPreview}
                                            />
                                        </div>
                                    </div>
                                )}
                                {totalExpiredRecord.length > 0 && (
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }}>
                                        <div style={{ width: '80%' }}>
                                            <ChartExtendMonth
                                                record={totalExpiredRecord}
                                                filtered={totalExpiredFilter}
                                                title='Số lượng sim cần gia hạn theo tháng'
                                                labelRecord='SL sim cần GH'
                                                labelFilter='SL sim cần GH có DL'
                                            />
                                            <ReactQuill
                                                key={isPreview ? "preview-mode" : "edit-mode"}
                                                value={value2}
                                                onChange={setValue2}
                                                theme={isPreview ? "bubble" : "snow"}
                                                readOnly={isPreview}
                                            />
                                        </div>
                                    </div>
                                )}
                                {totalExpiredRecord.length > 0 && (
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }}>
                                        <div style={{ width: '80%' }}>
                                            <ChartExtendDay
                                                record={totalExpiredRecord}
                                                filtered={totalExpiredFilter}
                                                title='SL sim hết hạn theo ngày'
                                            />
                                            <ReactQuill
                                                key={isPreview ? "preview-mode" : "edit-mode"}
                                                value={value3}
                                                onChange={setValue3}
                                                theme={isPreview ? "bubble" : "snow"}
                                                readOnly={isPreview}
                                            />
                                        </div>
                                    </div>
                                )}
                                {totalExtendedRecord.length > 0 && (
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }}>
                                        <div style={{ width: '80%' }}>
                                            <ChartMonth
                                                data={totalExtendedRecord}
                                                title='Số lượng sim gia hạn theo tháng'
                                                label='SL sim đã GH'
                                            />
                                            <ReactQuill
                                                key={isPreview ? "preview-mode" : "edit-mode"}
                                                value={value4}
                                                onChange={setValue4}
                                                theme={isPreview ? "bubble" : "snow"}
                                                readOnly={isPreview}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Card type='inner' size='small' title={<h2 style={{ textAlign: 'center', margin: 0 }}>Nhận xét</h2>}>
                                {listComments.length > 0 ? (
                                    <div>
                                        {listComments.slice((currentPage - 1) * pageSize, pageSize * currentPage).map((item: any) => (
                                            <div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Space style={{ fontWeight: 'bold' }}>
                                                        {partnerExtensionCommentOptions.find(type => type.value === item.sk?.split('#')[0])?.label}
                                                        {item.sk?.split('#')[1]}
                                                    </Space>
                                                    <Space style={{ fontStyle: 'italic' }}>
                                                        {item.owner_by}
                                                        {item.sk?.split('#')[2]}
                                                    </Space>
                                                </div>
                                                <ReactQuill
                                                    theme='bubble'
                                                    readOnly
                                                    value={item.result}
                                                />
                                                <Divider style={{ marginBottom: 10 }} />
                                            </div>
                                        ))}
                                    </div>
                                ) : <Empty />}
                                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                    <div>Tổng {listComments.length} nhận xét</div>
                                    <div>
                                        {!isPreview && (
                                            <Pagination
                                                showSizeChanger
                                                pageSize={pageSize}
                                                onShowSizeChange={(_current, size) => setPageSize(size)}
                                                total={listComments.length}
                                                current={currentPage}
                                                onChange={activePage => setCurrentPage(activePage)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Card>
                            <div style={{ display: 'flex', gap: 20, marginTop: 20 }}>
                                <div style={{ flex: 1 }}>
                                    <ReactQuill
                                        key={isPreview ? "preview-mode" : "edit-mode"}
                                        value={value5}
                                        onChange={setValue5}
                                        theme={isPreview ? "bubble" : "snow"}
                                        readOnly={isPreview}
                                    />
                                </div>
                                <div style={{ textAlign: 'end' }}>
                                    <div style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Tác giả: {account?.full_name || account?.username}</div>
                                    <i>{moment().format('DD-MM-YYYY')}</i>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            )}
        </div>
    )
}
