import APIUtils from './base'
import { ILoginParams, IRegisterParams } from '../models/login.model'
import { overrideAxiosDefaultConfig } from './config'
import { setupAxiosInterceptors } from './interceptor'
import { IChangePasswordParams, IUser } from '../models/user.model'
import { IGetListType } from '../models/request.model'
import { ICreateCompany, IListCompanyReq, IUpdateCompany } from '../models/company.modal'
import {
    ICreateListPackages,
    ICreatePackage,
    ICreatePartnerPackage,
    ICreatePartnerPackages,
    IListPackageReq,
    IListPartnerPackageReq,
    IUpdatePackage,
} from '../models/package.modal'
import { ICreateTelegram, IListTelegramReq, IUpdateTelegram } from '../models/telegram.modal'
import {
    IConnectTransaction,
    ICreateTransaction,
    IListTransactionReq,
    IRenewTransaction,
} from '../models/transaction.modal'
import { ICreateRole, IUpdateRole } from '../models/role.model'
import { IListSimPassportReq, IUpdateSimPassport } from '../models/sim-passport.modal'
import { apiPartnerCreateUserPartner } from './partner'

// Auth
export const apiLogin = (params: ILoginParams) => APIUtils.post('/otek-dashboard/auth/login', params)
export const apiGetInfoByToken = () => APIUtils.get('/otek-dashboard/auth/info')
export const apiUpdateInfo = (params: IUser) => APIUtils.patch('/otek-dashboard/auth/update', params)
export const apiChangePassword = (params: IChangePasswordParams) => APIUtils.patch('/otek-dashboard/auth/password', params)

// Company
export const apiGetListCompanies = (params?: IListCompanyReq) => APIUtils.get('/otek-dashboard/companies', params)
export const apiCreateCompany = (params?: ICreateCompany) => APIUtils.post('/otek-dashboard/companies', params)
export const apiUpdateCompany = (params?: IUpdateCompany) => APIUtils.patch('/otek-dashboard/companies', params)
export const apiDeleteCompany = (id?: Number) => APIUtils.delete(`/otek-dashboard/companies?id=${id}`)

// Package
export const apiGetListPackages = (params?: IListPackageReq) => APIUtils.get('/otek-dashboard/packages', params)
export const apiCreatePackage = (params?: ICreatePackage) => APIUtils.post('/otek-dashboard/packages', params)
export const apiCreateListPackages = (params?: ICreateListPackages) => APIUtils.post('/otek-dashboard/packages/add-list-packages', params)
export const apiUpdatePackage = (params?: IUpdatePackage) => APIUtils.patch('/otek-dashboard/packages', params)
export const apiDeletePackage = (id?: Number) => APIUtils.delete(`/otek-dashboard/packages?id=${id}`)
// Partner Package
export const apiGetListPartnerPackages = (params?: IListPartnerPackageReq) => APIUtils.get('/otek-dashboard/partner-packages', params)
export const apiCreatePartnerPackage = (params?: ICreatePartnerPackage) => APIUtils.post('/otek-dashboard/partner-packages', params)
export const apiCreateListPartnerPackages = (params?: ICreatePartnerPackages) => APIUtils.post('/otek-dashboard/partner-packages/add-list-partner-packages', params)
export const apiDeletePartnerPackage = (id?: Number) => APIUtils.delete(`/otek-dashboard/partner-packages?id=${id}`)
export const apiGetListPackagesTkgDashboard = () => APIUtils.get('/otek-dashboard/partner-packages/get-list-packages-tkg-dashboard')
export const apiGetListPackagesSponsor = () => APIUtils.get('/otek-dashboard/partner-packages/get-list-packages-sponsor')
export const apiGetListPackagesConnection = (cpCode: string) => APIUtils.get('/otek-dashboard/partner-packages/get-list-packages-connection', { cpCode })

// Transaction
export const apiGetListTransactions = (params?: IListTransactionReq) => APIUtils.get('/otek-dashboard/transactions', params)
export const apiCreateTransaction = (params?: ICreateTransaction) => APIUtils.post('/otek-dashboard/transactions', params)
export const apiRenewTransaction = (params?: IRenewTransaction) => APIUtils.patch('/otek-dashboard/transactions', params)
export const apiConnectTransaction = (params?: IConnectTransaction) => APIUtils.post('/otek-dashboard/transactions/connect', params)

// Telegram
export const apiGetListTelegrams = (params?: IListTelegramReq) => APIUtils.get('/otek-dashboard/telegrams', params)
export const apiCreateTelegram = (params?: ICreateTelegram) => APIUtils.post('/otek-dashboard/telegrams', params)
export const apiUpdateTelegram = (params?: IUpdateTelegram) => APIUtils.patch('/otek-dashboard/telegrams', params)
export const apiDeleteTelegram = (id?: Number) => APIUtils.delete(`/otek-dashboard/telegrams?id=${id}`)

// User
export const apiGetListUsers = (params?: IGetListType) => APIUtils.get('/otek-dashboard/users', params)
export const apiCreateUser = (params: IRegisterParams) => APIUtils.post('/otek-dashboard/users', params)
export const apiUpdateUser = (params: IUser) => APIUtils.patch('/otek-dashboard/users', params)
export const apiDeleteUser = (id?: Number) => APIUtils.delete(`/otek-dashboard/users?id=${id}`)

// Role
export const apiGetListRoles = (params?: IGetListType) => APIUtils.get('/otek-dashboard/roles', params)
export const apiCreateRole = (params?: ICreateRole) => APIUtils.post('/otek-dashboard/roles', params)
export const apiUpdateRole = (params?: IUpdateRole) => APIUtils.patch('/otek-dashboard/roles', params)
export const apiDeleteRole = (id?: Number) => APIUtils.delete(`/otek-dashboard/roles?id=${id}`)

// Base Permission
export const apiGetListBasePermissions = (params?: IGetListType) => APIUtils.get('/otek-dashboard/base-permissions', params)

// SimPassport
export const apiGetListSimPassports = (params?: IListSimPassportReq) => APIUtils.get('/otek-dashboard/sim-passports', params)
export const apiUpdateSimPassport = (params?: IUpdateSimPassport) => APIUtils.patch('/otek-dashboard/sim-passports', params)

// Mbh Topups
export const apiUploadOrderTopupByExcel = (formData?: any, desc?: string) => (
    APIUtils.post(
        `/otek-dashboard/mbh-topups/upload-order-topup-by-excel?description=${desc}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
    )
)
export const apiTopupByPhone = (params?: any) => APIUtils.post('/otek-dashboard/mbh-topups/topup-by-phone', params)
export const apiTopupByOrderId = (params?: any) => APIUtils.post('/otek-dashboard/mbh-topups/topup-by-order-id', params)
export const apiCheckBalanceTopup = (params?: any) => APIUtils.get('/otek-dashboard/mbh-topups/check-balance-topup', params)
export const apiGetListOrderTopups = (params?: any) => APIUtils.get('/otek-dashboard/mbh-topups/get-list-order-topups', params)
export const apiGetListTransactionTopupsByOrderId = (params?: any) => APIUtils.get('/otek-dashboard/mbh-topups/get-list-transaction-topups-by-order-id', params)
export const apiCheckTransactionTopupOnResource = (params?: any) => APIUtils.get('/otek-dashboard/mbh-topups/check-transaction-topup-on-resource', params)
export const apiCheckTransactionTopupOnAdapter = (params?: any) => APIUtils.get('/otek-dashboard/mbh-topups/check-transaction-topup-on-adapter', params)
export const apiUpdateStatusTransactionTopup = (params?: any) => APIUtils.patch('/otek-dashboard/mbh-topups/update-status-transaction-topup', params)
export const apiUpdateLockOrderTopup = (params?: any) => APIUtils.patch('/otek-dashboard/mbh-topups/update-lock-order-topup', params)

// Mbh TKG Dashboard
export const apiUploadOrderTkgDashboardByExcel = (formData?: any, desc?: string) => (
    APIUtils.post(
        `/otek-dashboard/mbh-tkg-dashboard/upload-order-tkg-dashboard-by-excel?description=${desc}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
    )
)
export const apiTkgDashboardByPhone = (params?: any) => APIUtils.post('/otek-dashboard/mbh-tkg-dashboard/tkg-dashboard-by-phone', params)
export const apiTkgDashboardByOrderId = (params?: any) => APIUtils.post('/otek-dashboard/mbh-tkg-dashboard/tkg-dashboard-by-order-id', params)
export const apiCheckBalanceTkgDashboard = (params?: any) => APIUtils.get('/otek-dashboard/mbh-tkg-dashboard/check-balance-tkg-dashboard', params)
export const apiGetListOrderTkgDashboard = (params?: any) => APIUtils.get('/otek-dashboard/mbh-tkg-dashboard/get-list-order-tkg-dashboard', params)
export const apiGetListTransactionTkgDashboardByOrderId = (params?: any) => APIUtils.get('/otek-dashboard/mbh-tkg-dashboard/get-list-transaction-tkg-dashboard-by-order-id', params)
export const apiCheckTransactionTkgDashboardOnResource = (params?: any) => APIUtils.get('/otek-dashboard/mbh-tkg-dashboard/check-transaction-tkg-dashboard-on-resource', params)
export const apiCheckTransactionTkgDashboardOnAdapter = (params?: any) => APIUtils.get('/otek-dashboard/mbh-tkg-dashboard/check-transaction-tkg-dashboard-on-adapter', params)
export const apiGetDynamicPartnerOffer = (params?: any) => APIUtils.get('/otek-dashboard/mbh-tkg-dashboard/get-dynamic-partner-offer', params)
export const apiUpdateStatusTransactionTkgDashboard = (params?: any) => APIUtils.patch('/otek-dashboard/mbh-tkg-dashboard/update-status-transaction-tkg-dashboard', params)
export const apiUpdateLockOrderkgDashboard = (params?: any) => APIUtils.patch('/otek-dashboard/mbh-tkg-dashboard/update-lock-order-tkg-dashboard', params)

// Data Sponsor
export const apiUploadOrderDataSponsorByExcel = (formData?: any, desc?: string) => (
    APIUtils.post(
        `/otek-dashboard/data-sponsor/upload-order-data-sponsor-by-excel?description=${desc}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
    )
)
export const apiDataSponsorByPhone = (params?: any) => APIUtils.post('/otek-dashboard/data-sponsor/data-sponsor-by-phone', params)
export const apiDataSponsorByOrderId = (params?: any) => APIUtils.post('/otek-dashboard/data-sponsor/data-sponsor-by-order-id', params)
export const apiCheckBalanceDataSponsor = (params?: any) => APIUtils.get('/otek-dashboard/data-sponsor/check-balance-data-sponsor', params)
export const apiGetListOrderDataSponsor = (params?: any) => APIUtils.get('/otek-dashboard/data-sponsor/get-list-order-data-sponsor', params)
export const apiGetListTransactionDataSponsorByOrderId = (params?: any) => APIUtils.get('/otek-dashboard/data-sponsor/get-list-transaction-data-sponsor-by-order-id', params)
export const apiCheckTransactionDataSponsorOnResource = (params?: any) => APIUtils.get('/otek-dashboard/data-sponsor/check-transaction-data-sponsor-on-resource', params)
export const apiCheckTransactionDataSponsorOnAdapter = (params?: any) => APIUtils.get('/otek-dashboard/data-sponsor/check-transaction-data-sponsor-on-adapter', params)
export const apiGetDynamicPartnerOfferDataSponsor = (params?: any) => APIUtils.get('/otek-dashboard/data-sponsor/get-dynamic-partner-offer-data-sponsor', params)
export const apiCheckWalletBalancePostpaid = (params?: any) => APIUtils.get('/otek-dashboard/data-sponsor/check-wallet-balance-postpaid', params)
export const apiUpdateStatusTransactionDataSponsor = (params?: any) => APIUtils.patch('/otek-dashboard/data-sponsor/update-status-transaction-data-sponsor', params)
export const apiUpdateLockOrderDataSponsor = (params?: any) => APIUtils.patch('/otek-dashboard/data-sponsor/update-lock-order-data-sponsor', params)

// User Partner
export const apiGetListUserPartners = (params?: any) => APIUtils.get('/otek-dashboard/user-partners/get-list-user-partners', params)
export const apiCreateUserPartner = (params: any) => apiPartnerCreateUserPartner(params)
export const apiUpdateUserPartner = (params: any) => APIUtils.patch('/otek-dashboard/user-partners/update-user-partner', params)
export const apiDeleteUserPartner = (id: number) => APIUtils.delete(`/otek-dashboard/user-partners/delete-user-partner?id=${id}`)

// Transaction Connection
export const apiAddTransactionConnection = (params?: any) => APIUtils.post('/otek-dashboard/transaction-connection/add-transaction-connection', params)
export const apiGetListTransactionConnection = (params?: any) => APIUtils.get('/otek-dashboard/transaction-connection/get-list-transaction-connection', params)
export const apiGetListTransactionConnectionExpire = (params?: any) => APIUtils.get('/otek-dashboard/transaction-connection/get-list-transaction-connection-expire', params)
export const apiGetListTransactionConnectionView = (params?: any) => APIUtils.get('/otek-dashboard/transaction-connection/get-list-transaction-connection-view', params)
export const apiUpdateListSimTransactionConnection = (params?: any) => APIUtils.put('/otek-dashboard/transaction-connection/update-list-sim-transaction-connection', params)
export const apiUpdatePhoneNumberTransactionConnection = (params?: any) => APIUtils.put('/otek-dashboard/transaction-connection/update-phone-number-transaction-connection', params)
export const apiUpdateDataPackTransactionConnection = (params?: any) => APIUtils.put('/otek-dashboard/transaction-connection/update-data-pack-transaction-connection', params)

// Statistical Service
export const apiGetRevenueConnection = (params?: any) => APIUtils.get('/otek-dashboard/statistical-service/get-revenue-connection', params)
export const apiGetRevenueDateConnection = (params?: any) => APIUtils.get('/otek-dashboard/statistical-service/get-revenue-date-connection', params)
export const apiGetStatisticalTypeConnection = (params?: any) => APIUtils.get('/otek-dashboard/statistical-service/get-statistical-type-connection', params)
export const apiGetStatisticalDateConnection = (params?: any) => APIUtils.get('/otek-dashboard/statistical-service/get-statistical-date-connection', params)
export const apiGetStatisticalPackDurationConnection = (params?: any) => APIUtils.get('/otek-dashboard/statistical-service/get-statistical-pack-duration-connection', params)
export const apiGetStatisticalTypeExpiration = (params?: any) => APIUtils.get('/otek-dashboard/statistical-service/get-statistical-type-expiration', params)
export const apiGetStatisticalDateExpiration = (params?: any) => APIUtils.get('/otek-dashboard/statistical-service/get-statistical-date-expiration', params)
export const apiGetStatisticalDateExtension = (params?: any) => APIUtils.get('/otek-dashboard/statistical-service/get-statistical-date-extension', params)
export const apiGetStatisticalDateCalling = (params?: any) => APIUtils.get('/otek-dashboard/statistical-service/get-statistical-date-calling', params)
export const apiGetStatisticalTypeCalling = (params?: any) => APIUtils.get('/otek-dashboard/statistical-service/get-statistical-type-calling', params)

// Customer Resource
export const apiGetListCustomerResource = (params?: any) => APIUtils.get('/otek-dashboard/customer-resource/get-list-customer-resource', params)
export const apiAddListCustomerResource = (params?: any) => APIUtils.post('/otek-dashboard/customer-resource/add-list-customer-resource', params)
export const apiUpdateListCustomerResource = (params?: any) => APIUtils.put('/otek-dashboard/customer-resource/update-list-customer-resource', params)

// Phone Agency
export const apiGetListPhoneAgencies = (params?: any) => APIUtils.get('/otek-dashboard/phone-agencies/get-list-phone-agencies', params)
export const apiAddListPhoneAgencies = (params?: any) => APIUtils.post('/otek-dashboard/phone-agencies/add-list-phone-agencies', params)
export const apiUpdateListPhoneAgencies = (params?: any) => APIUtils.put('/otek-dashboard/phone-agencies/update-list-phone-agencies', params)

// Call Info
export const apiGetListCallInfo = (params?: any) => APIUtils.get('/otek-dashboard/call-info/get-list-call-info', params)
export const apiAddListCallTelesale = (params?: any) => APIUtils.post('/otek-dashboard/call-info/add-list-call-telesale', params)
export const apiAddLogFeedback = (params?: any) => APIUtils.post('/otek-dashboard/call-info/add-log-feedback', params)
export const apiUpdateListCallInfo = (params?: any) => APIUtils.put('/otek-dashboard/call-info/update-list-call-info', params)

// Call Log
export const apiGetListCallLog = (params?: any) => APIUtils.get('/otek-dashboard/call-log/get-list-call-log', params)

export const settingAPI = ({ onUnauthenticatedCallback }: { onUnauthenticatedCallback: Function }) => {
    overrideAxiosDefaultConfig()
    setupAxiosInterceptors(onUnauthenticatedCallback)
}
