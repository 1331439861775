import { useCallback, useEffect, useState } from 'react'
import { Button, Divider, Form, Input, InputNumber, Modal, Select, Space, TableProps, Upload, message } from 'antd'
import { MoneyCollectOutlined, UploadOutlined, EyeOutlined, SearchOutlined, UndoOutlined, LockOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { toastError, toastSuccess } from '../../utils/toast'
import { useLoading } from '../../context/LoadingContext'
import { formatCurrency } from '../../utils/string-utils'
import { apiGetListOrderTopups, apiGetListUsers, apiTopupByOrderId, apiTopupByPhone, apiUpdateLockOrderTopup, apiUploadOrderTopupByExcel } from '../../api'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { DETAIL_TRANSACTION, TOP_UP } from '../../assets/constants/route_path'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { orderStatusOptions, priceTopupOptions } from '../../assets/constants/default_options'
import { IUser } from '../../models/user.model'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'
import { renderOrderStatus } from '.'

export default function ManagementTopUpOrder() {
    const [orderTopups, setOrderTopups] = useState<any>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        status: '',
    })
    const [modalStatus, setModalStatus] = useState<{ data: any | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalLock, setModalLock] = useState<{ data: any | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalTopupPhone, setModalTopupPhone] = useState<boolean>(false)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [modalUpload, setModalUpload] = useState<boolean>(false)
    const [fileExcel, setFileExcel] = useState<any>()
    const [fileList, setFileList] = useState([])
    const [descExcel, setDescExcel] = useState<any>()
    const [price, setPrice] = useState<number>()

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'orderId',
            title: 'Mã đơn hàng',
            dataIndex: 'orderId',
            render: orderId => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {orderId}
                </span>
            ),
        },
        {
            key: 'ownerBy',
            title: 'Nhân sự tạo đơn',
            dataIndex: 'ownerBy',
        },
        {
            key: 'description',
            title: 'Mô tả',
            dataIndex: 'description',
        },
        {
            key: 'totalPhoneNumber',
            title: 'Tổng số điện thoại',
            dataIndex: 'totalPhoneNumber',
        },
        {
            key: 'totalPrice',
            title: 'Tổng giá',
            dataIndex: 'totalPrice',
            render: (totalPrice) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(totalPrice)}
                </span>
            ),
        },
        {
            key: 'totalSuccess',
            title: 'Tổng thành công',
            dataIndex: 'totalSuccess',
        },
        {
            key: 'totalFail',
            title: 'Tổng thất bại',
            dataIndex: 'totalFail',
        },
        {
            key: 'reqTime',
            title: 'Thời gian tạo',
            dataIndex: 'reqTime',
            render: (reqTime) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(reqTime * 1000)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            )
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
            render: status => renderOrderStatus(status)
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: any) => (
                <Space>
                    {account.canGetListTransactionTopupsByOrderId && (
                        <Link to={TOP_UP + DETAIL_TRANSACTION + `?orderId=${data.orderId}`}>
                            <Button
                                icon={<EyeOutlined />}
                                style={{ background: '#289e45', color: 'white' }}
                            >
                                Chi tiết
                            </Button>
                        </Link>
                    )}
                    {account.canTopupByOrderId && (
                        <Button
                            disabled={data.status !== 'CREATED' || data.isLocked === true}
                            onClick={() => setModalStatus({ data, isOpen: true })}
                            icon={<MoneyCollectOutlined />}
                            type='primary'
                        >
                            Nạp tiền
                        </Button>
                    )}
                    {account.canUpdateLockOrderTopup && data.status === 'CREATED' && (
                        <Button
                            disabled={data.isLocked === true}
                            onClick={() => setModalLock({ data, isOpen: true })}
                            icon={<LockOutlined />}
                            type='primary'
                            danger
                        >
                        </Button>
                    )}
                </Space>
            ),
        },
    ]

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const respOrder = await apiGetListOrderTopups({
                page: currentPage,
                size: pageSize,
                status: search.status,
            })
            if (respOrder.data === null) {
                setOrderTopups({})
                return
            }
            setOrderTopups(respOrder.data)

            const respUsers = await apiGetListUsers({ limit: defaultParams.MAX_LIMIT })
            if (respUsers.data === null) {
                return
            }

            const resp = { total: respOrder.data.total, orders: [] as any }
            const listOrders = respOrder.data.orders
            const listUsers = respUsers.data.users
            for (let i = 0; i < listOrders.length; i++) {
                const order = listOrders[i]
                const userInfo = listUsers.find((user: IUser) => user.id === order.ownerBy)
                resp.orders.push({ ...order, ownerBy: userInfo?.full_name || userInfo?.username })
            }
            setOrderTopups(resp)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSearch = ({ status }: any) => {
        if (status) {
            setSearch({ status })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            status: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleTopupOrderId = async () => {
        try {
            setIsLoading(true)
            const resp = await apiTopupByOrderId({ orderId: modalStatus.data.id })
            if (resp.status === 200) {
                toastSuccess(resp.data.message)
                fetchData()
                handleCloseModalTopupOrderId()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }
    const handleCloseModalTopupOrderId = () => {
        setModalStatus({ data: null, isOpen: false })
        setPrice(undefined)
    }

    const handleShowModalConfirm = () => {
        if (!phoneNumber || !price) {
            toastError('Vui lòng nhập đầy đủ thông tin!')
            return
        }

        const regex = /^(0|84)?\d{9,10}$/
        if (!regex.test(phoneNumber.replaceAll(' ', ''))) {
            toastError('Vui lòng nhập số điện thoại hợp lệ!')
            return
        }

        setShowModalConfirm(true)
    }

    const handleTopupPhone = async () => {
        try {
            setIsLoading(true)
            const resp = await apiTopupByPhone({ phoneNumber: phoneNumber.trim(), price })
            if (resp.status === 200) {
                setTimeout(() => {
                    toastSuccess(resp.data.message)
                    fetchData()
                    handleCloseModalTopupPhone()
                    setShowModalConfirm(false)
                    setIsLoading(false)
                }, 5000)
            } else {
                toastError('Có lỗi xảy ra!')
                setIsLoading(false)
            }
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }
    const handleCloseModalTopupPhone = () => {
        setModalTopupPhone(false)
        setPhoneNumber('')
        setPrice(undefined)
    }

    const handleUploadFile = async () => {
        if (!fileExcel || !descExcel) {
            toastError('Vui lòng nhập đầy đủ thông tin!')
            return
        }

        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.append("file", fileExcel.file)
            const resp = await apiUploadOrderTopupByExcel(formData, descExcel)
            if (resp.status === 200) {
                toastSuccess('nạp tiền đang thực hiện! Vui lòng kiểm tra kết quả sau ít phút')
                fetchData()
                handleCloseModalUploadFile()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }
    const handleCloseModalUploadFile = () => {
        setModalUpload(false)
        setFileExcel({})
        setDescExcel('')
    }
    const checkUploadFile = (file: any) => {
        const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'
        if (!isExcel) {
            message.error('Vui lòng upload file dạng excel!')
            return Upload.LIST_IGNORE
        }
        return false
    }
    const handleChangeFile = (value: any) => {
        setFileList(value.fileList)
        setFileExcel(value)
    }

    const handleLock = async () => {
        try {
            setIsLoading(true)
            await apiUpdateLockOrderTopup({
                orderId: modalLock.data?.id,
                isLocked: true,
                status: 'CANCELLED',
            })
            fetchData()
            setModalLock({ isOpen: false, data: null })
            toastSuccess('Khoá đơn hàng thành công!')
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <Form form={formSearch} onFinish={handleSearch}>
                <Space style={{ display: 'flex', alignItems: 'start' }}>
                    <Form.Item name='status' label='Trạng thái'>
                        <Select
                            style={{ width: 170 }}
                            showSearch
                            placeholder="Chọn trạng thái"
                            options={orderStatusOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Space>
                        <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </Space>
            </Form>
            <div style={{ textAlign: 'end', marginBottom: 20 }}>
                <Space>
                    {account.canTopupByPhone && (
                        <Button onClick={() => setModalTopupPhone(true)} type='dashed'>Nạp tiền SĐT</Button>
                    )}
                    {account.canUploadOrderTopupByExcel && (
                        <Button onClick={() => setModalUpload(true)} type='primary'>Import đơn hàng (File Excel)</Button>
                    )}
                </Space>
            </div>
            <CustomTable
                namePage='đơn hàng'
                columns={columns}
                dataSource={orderTopups.orders}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={orderTopups.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Nạp tiền' open={modalStatus.isOpen}
                onCancel={handleCloseModalTopupOrderId}
                footer={[
                    <Button onClick={handleTopupOrderId}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalTopupOrderId}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Nạp tiền cho đơn hàng <b>{modalStatus.data?.orderId}</b></p>
                <Divider />
            </Modal>

            <Modal title='Nạp tiền SĐT' open={modalTopupPhone}
                onCancel={handleCloseModalTopupPhone}
                footer={[
                    <Button onClick={handleShowModalConfirm}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalTopupPhone}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Nhập số điện thoại</p>
                <Input value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} style={{ width: '100%' }} />
                <p>Nhập số tiền cần nạp</p>
                <InputNumber
                    formatter={value => formatCurrency(Number(value))}
                    value={price} onChange={value => setPrice(Number(value))}
                    style={{ width: '100%', marginBottom: 20 }}
                />
                <Space wrap>
                    {priceTopupOptions.map((item: any) => {
                        return <Button onClick={() => setPrice(item.value)} type={item.value === price ? 'primary' : 'dashed'}>
                            {item.label}
                        </Button>
                    })}
                </Space>
                <Divider />
            </Modal>
            <Modal title='Xác nhận nạp tiền' open={showModalConfirm}
                onCancel={() => setShowModalConfirm(false)}
                footer={[
                    <Button onClick={handleTopupPhone}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={() => setShowModalConfirm(false)}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <p>Nạp {formatCurrency(Number(price))} cho số điện thoại {phoneNumber}</p>
            </Modal>

            <Modal title='Upload' open={modalUpload}
                onCancel={handleCloseModalUploadFile}
                footer={[
                    <Button onClick={handleUploadFile}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalUploadFile}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Đăng tải File Excel</p>
                <Upload
                    maxCount={1}
                    fileList={fileList}
                    onChange={handleChangeFile}
                    beforeUpload={checkUploadFile}
                >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
                <p>Nhập mô tả</p>
                <Input value={descExcel} onChange={e => setDescExcel(e.target.value)} />
                <Divider />
                <p><b>Ghi chú</b></p>
                <p>Định dạng: Số điện thoại và giá tiền</p>
                <p>Tên sheet: Sheet1</p>
                <p>Tối đa: 1000 số điện thoại</p>
                <Divider />
            </Modal>

            <Modal title='Khoá đơn hàng' open={modalLock.isOpen}
                onCancel={() => setModalLock({ isOpen: false, data: null })}
                footer={[
                    <Button onClick={handleLock} type='primary' danger>
                        Xác nhận
                    </Button>,
                    <Button onClick={() => setModalLock({ isOpen: false, data: null })}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Bạn có chắc muốn khoá đơn hàng <b>{modalLock.data?.orderId}</b></p>
                <Divider />
            </Modal>
        </div>
    )
}
